import { types } from 'mobx-state-tree';

import { Model } from '../utils/mobx-model-helper';

import { getListSeminars } from '../api/seminars';

const TYPES = { GET_LIST_SEMINARS: 1 };

const Seminar = types.model('Seminar')
  .props({
    id: types.maybeNull(types.identifierNumber),
    displayFlg: types.maybeNull(types.boolean),
    time: types.maybeNull(types.string),
    imageUrl: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    audience: types.maybeNull(types.string),
    link: types.maybeNull(types.string),
    tags: types.maybeNull(types.array(types.string)),
    content: types.maybeNull(types.string)
  })

const SeminarStore = Model.named('SeminarStore')
  .props({
    seminars: types.model({
      page: types.number,
      total: types.number,
      items: types.array(Seminar)
    })
  })
  .actions(self => ({
    getListSeminars(payload) {
      return self.request({
        type: TYPES.GET_LIST_SEMINARS,
        api: getListSeminars,
        payload,
        onSuccess: (result) => {
          self.seminars.page = result.page
          self.seminars.total = result.total
          self.seminars.items = result.result
        },
      })
    }
  }))

export { TYPES };

export default SeminarStore.create({
  seminars: {
    page: 0,
    total: 0,
    items: []
  }
});
