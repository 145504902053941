import { MainApi } from './endpoint'

export function getNotificationsStatistic() {
  return MainApi.get('/user/notifications/statistic');
}

export function getListNotifications(payload) {
  return MainApi.get('/user/notifications', payload);
}


export function getNotificationDetail(id) {
  return MainApi.get(`/user/notifications/${id}`);
}

export function getCommentsByNotiId({ id, ...payload }) {
  return MainApi.get(`/user/notifications/${id}/comments`, payload);
}

export function commentInNotification({ id, ...payload }) {
  return MainApi.post(`/user/notifications/${id}/comment`, payload)
}

export function updateCommentInNotification({ id, commentId, ...payload }) {
  return MainApi.put(`/user/notifications/${id}/comment/${commentId}`, payload)
}

export function deleteCommentInNotification({ id, commentId }) {
  return MainApi.delete(`/user/notifications/${id}/comment/${commentId}`)
}

export function getListLowComments({ id, parentCommentId, ...payload }) {
  return MainApi.get(`/user/notifications/${id}/low-comment/${parentCommentId}`, payload);
}

export function commentInCommentNotification({ id, parentCommentId, ...payload }) {
  return MainApi.post(`/user/notifications/${id}/low-comment/${parentCommentId}`, payload)
}

export function getListNotificationsActive(payload) {
  return MainApi.put('/user/notifications/activity', payload)
}

export function updateNotificationsActivity({id, ...payload}) {
  return MainApi.put(`/user/notifications/update-activity/${id}`, payload)
}

export function getListNotificationsPinned() {
  return MainApi.get('/user/notifications/pinned');
}

export function approveChoiceMember({ id }) {
  return MainApi.put(`/user/project/${id}/approve-choice`);
}

