/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { WALLET_ADAPTERS } from '@web3auth/base';
import { Image, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Images } from '../theme';
import Toast from '../components/toast';
import Storage from '../utils/storage';
import Request from '../utils/request';
import Misc from '../utils/misc';
import Web3 from '../utils/web3other';
import { useWeb3Auth } from '../contexts/web3auth/web3auth';
import useModalInvalidEmail from '../hooks/useModalInvalidEmail';
import Confirmable from '../components/confirmable';
import styled from 'styled-components';
import ModalComponent from './modal';
import { WALLET_LIST } from '@/constants/wallet-list';
import { logout as logoutSystem } from '@/utils/auth';
import MaskLoading from './mask-loading';
import Media from '@/utils/media';

const WrapButtonStyled = styled.div`
  display: flex;
  /* justify-content: space-between; */
  gap: 15px;
  padding: 10px;
  .button-social {
    flex: 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 60px;
    background: #1877f2;
    border-radius: 6px;
  }

  .google {
    background: #0099ff;
  }

  .twitter {
    background: #03a9f4;
  }
  .discord {
    background: #5865f2;
  }
`;

const WrapComponentLogin = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ItemLogin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: calc((100% - 30px) / 2); */
  flex: 1;

  .images {
    width: 125px;
    height: 125px;
  }
`;

const ButtonStyled = styled(Button)`
  color: #ffffff;
  border-radius: 50px;
  height: auto;
  margin-bottom: 20px;
  width: 100%;
  margin-top: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  span {
    word-wrap: break-word;
    white-space: break-spaces;
    ${Media.lessThan(Media.SIZE.MD)} {
      font-size: 10px;
    }
  }

  &:last-child {
    margin-left: 20px;
  }

  &:disabled {
    opacity: 0.9;
  }
`;

const ModalLoginSocial = styled(ModalComponent)`
  .ant-modal-close {
    top: 16px;
    right: 16px;
  }
  .ant-modal-close-x {
    width: 20px;
    height: 20px;
  }
`;

const LoginWeb3AuthComponent = ({ onClose }) => {
  const { t } = useTranslation('auth');
  const { auth } = useContext(MobXProviderContext);

  const {
    login: loginByWeb3auth,
    logout: logoutByWeb3auth,
    web3Auth,
    getAccounts,
    getUserInfo,
    getBalance: getBalanceWeb3auth,
    getAppPubKey,
    provider,
  } = useWeb3Auth();
  const { open: openModalInvalidEmail } = useModalInvalidEmail();
  const [loading, setLoading] = useState(false);

  const [isOpenSocial, setIsOpenSocial] = useState(false);

  const handleCloseModal = () => {
    formik.resetForm({});
    if (onClose) onClose();
  };

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email(t('validation_messages:EMAIL_INVALID'))
        .max(30, t('validation_messages:MAX_LENGTH_30'))
        .required(t('validation_messages:EMAIL_REQUIRED')),
    }),
    enableReinitialize: true,
    onSubmit: ({ email }) => {
      handleLoginWeb3Auth(WALLET_ADAPTERS.OPENLOGIN, 'email_passwordless', email);
    },
  });

  const _generateDappUrl = ({ link, name }) => {
    if (link) {
      if (name === 'Metamask') {
        const dappUrl = `${window.location.href}`.trim();
        if (dappUrl.search('https://') !== -1) {
          const pageUrl = `${link}/dapp/${dappUrl.replace('https://', '')}`;

          window.location.href = pageUrl;
        }
      }
    }
  };

  const handleLoginWeb3Auth = async (adapter, social = null, valueSocial = null) => {
    if (loading) return null;
    setLoading(true);
    setIsOpenSocial(false)
    onClose()
    MaskLoading.open({ message: t("inprocess_login", { ns: "common" }), zIndex: 1002 })
    try {
      const responseWeb3Auth = await loginByWeb3auth(adapter, social, valueSocial);

      const idSession = Storage.get('openlogin_store');
      const cachedAdapter = localStorage.getItem('Web3Auth-cachedAdapter');
      const dataObj = { idSession, cachedAdapter };
      const web3SessionData = JSON.stringify(dataObj);

      if (!responseWeb3Auth) {
        return;
      }

      if (adapter === WALLET_ADAPTERS.OPENLOGIN) {
        Storage.set('LONGIN_BY', 'SOCIAL');

        const userInfo = await getUserInfo();
        const accounts = await getAccounts();
        const appPubKey = await getAppPubKey();
        const balance = await getBalanceWeb3auth();
        const balanceUsdt = await provider.getBalanceUsdt();
        const balanceWeth = await provider.getBalanceWeth();
        const publicAddress = responseWeb3Auth?.[0] || accounts?.[0];

        const payLoad = {
          aggregateVerifier: userInfo?.aggregateVerifier,
          appPubKey: appPubKey,
          publicAddress,
          idToken: userInfo?.idToken,
          verifierId: userInfo?.verifierId,
          web3SessionData,
        };

        const responseData = await auth?.web3authlogin(payLoad);

        const { success, data } = responseData;

        if (success) {
          setIsOpenSocial(!isOpenSocial);
          Storage.set('ACCESS_TOKEN', data.accessToken);
          Storage.set('USER_ID', data.userId);
          Storage.set('PUBLIC_ADDRESS', publicAddress);
          Request.setAccessToken(data.accessToken);
          await auth.setLoggedIn(true);
          await auth.setInitialData({
            ethBalance: balance ?? 0,
            usdtBalance: balanceUsdt ?? 0,
            wethBalance: balanceWeth ?? 0,
            publicAddress,
            userId: data.userId,
          });
          if (data?.inactive) {
            openModalInvalidEmail();
          }
          await auth.getInitialData();
          await auth.getBalanceNftByTokenId();
          handleCloseModal();
          MaskLoading.close();
        } else {
          logoutByWeb3auth();
          if (data?.error === 'UNAUTHORIZED_USER_BLOCKED' || data?.error === 'UNAUTHORIZED') {
            Confirmable.open({
              content: t('login.messages.user_not_forbidden'),
              hideCancelButton: true,
            });
          } else {
            setIsOpenSocial(!isOpenSocial);
          }
        }
      } else {
        Storage.set('LONGIN_BY', 'METAMASK');
        const web3Info = await Web3?.getWeb3Instance();

        await Web3.checkValidNetwork()

        const accounts = await getAccounts();
        const balance = await getBalanceWeb3auth();
        
        const { idToken } = await web3Auth.authenticateUser();

        let web3;
        web3 = web3Info?.web3;
        web3?.currentProvider?.on('accountsChanged', () => {
          logoutSystem();
        });

        const { eth, weth, usdt } = await Web3.getBalance(responseWeb3Auth?.[0] || accounts?.[0]);

        const responseData = await auth?.web3authlogin({
          externalWallet: true,
          publicAddress: responseWeb3Auth?.[0] || accounts?.[0],
          idToken,
          web3SessionData,
        });

        const { success, data, payload } = responseData;

        if (success) {
          Storage.set('ACCESS_TOKEN', data.accessToken);
          Storage.set('USER_ID', data.userId);
          Storage.set('PUBLIC_ADDRESS', payload.publicAddress);
          Request.setAccessToken(data.accessToken);

          auth.setInitialData({
            publicAddress: payload.publicAddress,
            userId: data.userId,
            ethBalance: balance ?? eth ?? 0,
            usdtBalance: usdt ?? 0,
            wethBalance: weth ?? 0,
          });
          if (data?.inactive) {
            openModalInvalidEmail();
          }
          await auth.getInitialData();
          await auth.getBalanceNftByTokenId();
          await auth.setLoggedIn(true);
          handleCloseModal();
          MaskLoading.close();
          // window.location.href = `${process.env.REACT_APP_URL || 'https://dev-daosite.rakuza.io/ja'}/top`;
        } else {
          Storage.remove('LONGIN_BY');
          logoutByWeb3auth();
          if (data?.error === 'UNAUTHORIZED_USER_BLOCKED' || data?.error === 'UNAUTHORIZED') {
            Confirmable.open({
              content: t('login.messages.user_not_forbidden'),
              hideCancelButton: true,
              otherFunctionHandle: MaskLoading.close(),
            });
          }
        }
      }
    } catch (e) {
      if (e?.code === 5111 && !window.ethereum && !Misc.isMobile) {
        Confirmable.open({
          content: (
            <>
              {t('common:for_access_from_pc')}
              <br />
              <br />
              {t('common:for_access_from_mobile')}
              <br />
              <br />
              {t('common:otherwise_error_login')}
            </>
          ),
          hideCancelButton: true,
        });
      } else if (e?.code != -32603) {
        if (e?.code === 4001) {
          Confirmable.open({
            content: <>{t("DENIED_SIGNATURE", {ns: "error-messages"})}</>,
            hideCancelButton: true,
            otherFunctionHandle: () => {
              MaskLoading.close()
              onClose()
            },
          });
        } else {
          Confirmable.open({
            content: <>{t(e.message)}</>,
            hideCancelButton: true,
            otherFunctionHandle: () => {
              MaskLoading.close();
              onClose();
            },
          });
        }
        // user reject
      } else {
        Toast.error(t('login.messages.cannot_login'));
      }
      Storage.remove('ACCESS_TOKEN');
      Storage.remove('USER_ID');
      Storage.remove('HIDE_BANNER');
      logoutByWeb3auth();
      Request.removeAccessToken();
      auth.logout();
    } finally {
      setLoading(false);
    }
  };

  return (
    <WrapComponentLogin>
      <ItemLogin>
        <Image className="images" preview={false} src={Images.COLOR_METAMASK_ICON} alt="" />
        <ButtonStyled
          onClick={() => {
            if (!window.ethereum && !Misc.isMobile) {
              Confirmable.open({
                content: (
                  <>
                    {t('common:for_access_from_pc')}
                    <br />
                    <br />
                    {t('common:for_access_from_mobile')}
                    <br />
                    <br />
                    {t('common:otherwise_error_login')}
                  </>
                ),
                hideCancelButton: true,
              });
            } else if (!window.ethereum && Misc.isMobile) {
              _generateDappUrl(WALLET_LIST[0]);
              return;
            } else {
              handleLoginWeb3Auth(WALLET_ADAPTERS.METAMASK);
            }
          }}
        >
          Metamaskを接続
        </ButtonStyled>
      </ItemLogin>
      {/* <ItemLogin>
        <Image className="images" preview={false} src={Images.DAOSITE_ICO_SOCIAL} alt="" />

        <ButtonStyled
         onClick={() => setIsOpenSocial(!isOpenSocial)}>
          ソーシャルアカウント から作成へ
        </ButtonStyled>
        <ModalLoginSocial
          visible={isOpenSocial}
          width="400"
          destroyOnClose
          onCancel={() => setIsOpenSocial(!isOpenSocial)}
        >
          <WrapButtonStyled className="wrap-button">
            <div
              className="button-social google"
              onClick={() => !loading && handleLoginWeb3Auth(WALLET_ADAPTERS.OPENLOGIN, 'google')}
            >
              <img src={Images.COLOR_GOOGLE_ICON} alt="GOOGLE_ICON" />
            </div>

            <div
              className="button-social facebook"
              onClick={() => !loading && handleLoginWeb3Auth(WALLET_ADAPTERS.OPENLOGIN, 'facebook')}
            >
              <img src={Images.COLOR_FACEBOOK_ICON} alt="FACEBOOK_ICON" />
            </div>

            <div className="button-social twitter">
              <img
                src={Images.COLOR_TWITTER_ICON}
                alt="TWITTER_ICON"
                onClick={() => !loading && handleLoginWeb3Auth(WALLET_ADAPTERS.OPENLOGIN, 'twitter')}
              />
            </div>

            <div className="button-social discord">
              <img
                src={Images.COLOR_DISCORD_ICON}
                alt="DISCORD_ICON"
                onClick={() => !loading && handleLoginWeb3Auth(WALLET_ADAPTERS.OPENLOGIN, 'discord')}
              />
            </div>
          </WrapButtonStyled>
        </ModalLoginSocial>
      </ItemLogin> */}
    </WrapComponentLogin>
  );
};

export default LoginWeb3AuthComponent;
