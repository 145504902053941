import { MainApi } from './endpoint';

export function getRewardHistory(payload) {
  return MainApi.get('/user/reward', payload);
}

export function getRewardWithdrawHistory(payload) {
  return MainApi.get('/user/reward/withdrawal', payload);
}

export function getRewardWithdrawDetail({id}) {
  return MainApi.get(`/user/reward/withdrawal/${id}`);
}

export function withdrawalReward(payload) {
  return MainApi.post('/user/reward/withdrawal', payload);
}

export function verifyRequestWithdrawal(payload) {
  return MainApi.post('/user/withdraw/verify-request', payload);
}

export function confirmRequestWithdrawal(payload) {
  return MainApi.post('/user/withdraw/confirm-request', payload);
}
