import { MainApi } from './endpoint';

export function getBalanceNftByTokenId() {
  return MainApi.get('/user/token-balance');
}

export function getUserNonce(userId) {
  return MainApi.get(`/user/identity/nonce/${userId}`);
}

export function verifySignature(payload) {
  return MainApi.post('/user/authentication/verify-signature', payload);
}

export function login(payload) {
  return MainApi.post('/user/authentication/sign-in', payload);
}

export function web3authlogin(payload) {
  return MainApi.post('/user/auth/web3-auth', payload);
}

export function getInitialData(payload) {
  return MainApi.get('/user/initial', payload);
}

export function updateAccelerationRate(payload) {
  const { accelerationRate, id } = payload;
  return MainApi.put(`/user/artist/gas-rate/${id}`, { accelerationRate: accelerationRate.toString() || '' });
}

export function fetchPrefecture(payload) {
  return MainApi.get('/prefecture', payload);
}

export function postMemberRegister(payload) {
  return MainApi.post('/user/members', payload);
}

export function getMember(memberId) {
  return MainApi.get(`/user/members/${memberId}`);
}

export function updateUserMember(payload) {
  const { params, memberId } = payload;
  return MainApi.put(`/user/members/${memberId}`, { ...params });
}

export function deleteMember(memberId) {
  return MainApi.delete(`/user/members/${memberId}`);
}

export function getMemberReward(payload) {
  return MainApi.get(`/user/members/reward-statistic`, payload);
}

export function checkMemberBelongToProjectBeforeQuit() {
  return MainApi.get(`/user/members/project-active`);
}

export function getCurrenciesByUser() {
  return MainApi.get('/currency');
}

export function getMemberCheckReward() {
  return MainApi.get('/user/members/unclaimed-reward')
}