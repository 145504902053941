// import { types } from 'mobx-state-tree';

import { Model } from '../utils/mobx-model-helper';

import {
  getRewardHistory,
  getRewardWithdrawHistory,
  getRewardWithdrawDetail,
  withdrawalReward,
  verifyRequestWithdrawal,
  confirmRequestWithdrawal
} from '../api/rewards';

const TYPES = {
  GET_REWARD_HISTORY: 1,
  GET_REWARD_WITHDRAW_HISTORY: 2,
  GET_REWARD_WITHDRAW_DETAIL: 3,
  WITHDRAWAL_REWARD: 4,
  VERIFY_REQUEST_WITHDRAWAL: 5,
  CONFIRM_REQUEST_WITHDRAWAL: 6,
}


const RewardStores = Model.named('RewardStores')
  .props({
  })
  .actions(self => ({

    getRewardHistory(payload) {
      return self.request({
        type: TYPES.GET_REWARD_HISTORY,
        api: getRewardHistory,
        payload
      })
    },
    getRewardWithdrawHistory(payload) {
      return self.request({
        type: TYPES.GET_REWARD_WITHDRAW_HISTORY,
        api: getRewardWithdrawHistory,
        payload
      })
    },
    getRewardWithdrawDetail(payload) {
      return self.request({
        type: TYPES.GET_REWARD_WITHDRAW_DETAIL,
        api: getRewardWithdrawDetail,
        payload
      })
    },

    withdrawalReward(payload) {
      return self.request({
        type: TYPES.WITHDRAWAL_REWARD,
        api: withdrawalReward,
        payload
      })
    },

    verifyRequestWithdrawal(payload) {
      return self.request({
        type: TYPES.VERIFY_REQUEST_WITHDRAWAL,
        api: verifyRequestWithdrawal,
        payload
      })
    },

    confirmRequestWithdrawal(payload) {
      return self.request({
        type: TYPES.CONFIRM_REQUEST_WITHDRAWAL,
        api: confirmRequestWithdrawal,
        payload
      })
    },

  }))

  export { TYPES };

  export default RewardStores.create({

  })