import { tokens } from './tokens';

export const CHAIN_LIST = {
  1: {
    env: ['production'],
    chainId: 1,
    displayName: 'Ethereum Mainnet',
    currency: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    blockExplorerUrls: ['https://etherscan.io/'],
    rpcUrls: [ process.env.REACT_APP_PROVIDER_INFURA_ID
      // ,process.env.REACT_APP_RPC_URL, 'https://rpc.ankr.com/eth', 'https://ethereum.publicnode.com'
    ],
    exchangeContractAddress: process.env.REACT_APP_EXCHANGE_CONTRACT_ADDRESS,
    nft721aContractAddress: process.env.REACT_APP_ERC721A_CONTRACT_ADDRESS,
    lendingContractAddress: process.env.REACT_APP_LENDING_CONTRACT_ADDRESS,
    managerStakingContractAddress: process.env.REACT_APP_MANAGER_STAKING_CONTRACT_ADDRESS,
    borrowContractAddress: '',
    erc20Token: [tokens.usdt[1]],
  },
  11155111: {
    env: ['development', 'local', 'staging'],
    chainId: 11155111,
    displayName: 'Sepolia',
    currency: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    blockExplorerUrls: ['https://sepolia.etherscan.io/'],
    rpcUrls: [
      process.env.REACT_APP_PROVIDER_INFURA_ID
      // 'https://eth-sepolia.g.alchemy.com/v2/OCX0YQuzhEtpj1O6r1lqUoj3asqwxu00'
    ],
    exchangeContractAddress: process.env.REACT_APP_EXCHANGE_CONTRACT_ADDRESS,
    nft721aContractAddress: process.env.REACT_APP_ERC721A_CONTRACT_ADDRESS,
    lendingContractAddress: process.env.REACT_APP_LENDING_CONTRACT_ADDRESS,
    managerStakingContractAddress: process.env.REACT_APP_MANAGER_STAKING_CONTRACT_ADDRESS,
    borrowContractAddress: '',
    erc20Token: [tokens.usdt[11155111]],
  }
};
