import { types } from 'mobx-state-tree';

import { Model } from '../utils/mobx-model-helper';

import {
  getListProjects,
  createProject,
  getProjectById,
  updateProject,
  joinRequestProject,
  getListProjectMemberForOwner,
  getListProjectMemberApply,
  getProjectMemberForUser,
  userUpdateRequestJoinProject,
  getListUserJoined,
  getListUserOwn,
  ownerUpdateMemberProject,
  deleteMemberJoinProject,
  memberChoiceProject,
  memberleaveProject,
  getListProjectMessageDetail,
  addNewMessageByProject,
  memberApproveChoiceProject,
  memberRejectChoiceProject,
  ownerDeleteProject,
  deleteMessage,
  editMessage,
  getProjectThreadMessage,
  getListProjectsRecruiting,
  getMylistProject,
  getMyProjectDetail,
  getListMemberByMyProjectId,
  getAboutProjects,
  getStatisticMyProjects,
  getProjectRecruitingById,
  ownerRejectMemberApply,
  ownerApproveMemberApply,
  createWalletForProject,
  ownerChoiceMembersForProject,
  removeMember,
  createCollectionInSmartContract,
  ownerUpdateProjectMemberRate,
  requestToRecruiting,
  userApproveNewRate,
  userRejectNewRate,
  requestReRecruiting,
  updateRequestReRecruiting,
  memberRequestLeaveProject,
  ownerRejectMemberLeaveProject,
  ownerApproveMemberLeaveProject,
  memberApproveStopProject,
  memberRejectStopProject,
  reUpdateContract,
  createProjectStoreRakuza,
  addContractManagementWithRoleOperator,
  paymentFeeForSystemToActiveStore
} from '../api/projects';

const TYPES = {
  GET_LIST_PROJECTS: 1,
  CREATE_PROJECT: 2,
  GET_PROJECT_BY_ID: 3,
  UPDATE_PROJECT: 4,
  DELETE_PROJECT: 5,
  JOIN_REQUEST_PROJECT: 6,
  GET_LIST_PROJECT_MEMBER_FOR_OWNER: 7,
  GET_LIST_PROJECT_MEMBER_APPLY: 8,
  GET_PROJECT_MEMBER_FOR_USER: 9,
  USER_UPDATE_REQUEST_JOIN_PROJECT: 10,
  GET_LIST_USER_JOINED: 11,
  GET_LIST_USER_OWN: 12,
  OWNER_UPDATE_MEMBER_PROJECT: 13,
  MEMBER_CHOICE_PROJECT: 14,
  MEMBER_LEAVE_PROJECT: 15,
  GET_LIST_PROJECT_MESSAGE_DETAIL: 16,
  ADD_NEW_MESSAGE_BY_PROJECT: 17,
  DELETE_MESSAGE: 18,
  EDIT_MESSAGE: 19,
  MEMBER_APPROVE_CHOICE_PROJECT: 20,
  MEMBER_REJECT_CHOICE_PROJECT: 21,
  OWNER_DELETE_PROJECT: 22,
  GET_PROJECT_THREAD_MESSAGE: 23,
  GET_LIST_PROJECTS_RECRUITING: 24,
  GET_MY_LIST_PROJECT: 25,
  GET_MY_PROJECT_DETAILD: 26,
  GET_LIST_MEMBER_BY_MY_PROJECT_ID: 27,
  GET_ABOUT_PROJECTS: 28,
  GET_STATISTIC_MY_PROJECTS: 29,
  GET_PROJECT_RECRUITING_BY_ID: 30,
  OWNER_REJECT_MEMBER_APPLY: 31,
  OWNER_APPROVE_MEMBER_APPLY: 32,
  CREATE_WALLET_FOR_PROJECT: 33,
  OWNER_CHOICE_MEMBERS_FOR_PROJECT: 34,
  REMOVE_MEMBER: 35,
  CREATE_COLLECTION_IN_SMART_CONTRACT: 36,
  OWNER_UPDATE_PROJECT_MEMBER_RATE: 37,
  REQUEST_TO_RECRUITING: 38,
  USER_APPROVE_NEW_RATE: 39,
  USER_REJECT_NEW_RATE: 40,
  REQUEST_RE_RECRUITING: 41,
  UPDATE_REQUEST_RE_RECRUITING: 42,
  MEMBER_REQUEST_LEAVE_PROJECT: 43,
  OWNER_REJECT_MEMBER_LEAVE_PROJECT: 44,
  OWNER_APPROVE_MEMBER_LEAVE_PROJECT: 45,
  MEMBER_APPROVE_STOP_PROJECT: 36,
  MEMBER_REJECT_STOP_PROJECT: 37,
  RE_UPDATE_CONTRACT: 38,
  CREATE_PROJECT_STORE_RAKUZA: 39,
  ADD_CONTRACT_MANAGEMENT_WITH_ROLE_OPERATOR: 40,
  PAYMENT_FEE_FOR_SYSTEM_TO_ACTIVE_STORE: 41
};


const MyProjectDetail = types.model('MyProjectDetail')
  .props({
    assets: types.maybeNull(types.string),
    code: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.number),
    id: types.identifierNumber,
    modifiedAt: types.maybeNull(types.number),
    notifications: types.array(types.model({
      id: types.identifierNumber,
      memberStatus: types.maybeNull(types.string),
      projectMemberId: types.maybeNull(types.number),
      projectMemberImage: types.maybeNull(types.string),
      projectMemberName: types.maybeNull(types.string)
    })),
    ownerId: types.maybeNull(types.number),
    ownerImage: types.maybeNull(types.string),
    ownerName: types.maybeNull(types.string),
    projectMembers: types.array(types.model({
      id: types.maybeNull(types.number),
      memberStatus: types.maybeNull(types.string),
      projectMemberId: types.maybeNull(types.number),
      projectMemberImage: types.maybeNull(types.string),
      projectMemberName: types.maybeNull(types.string)
    })),
    recruitFlg: types.maybeNull(types.boolean),
    recruitmentRoleSettings: types.array(types.model({
      role: types.maybeNull(types.string),
      totalMember: types.maybeNull(types.number),
      roleSettingDescription: types.maybeNull(types.string)
    })),
    status: types.maybeNull(types.string),
    storeUrl: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    totalReward: types.maybeNull(types.string)
  })

const Project = types.model('Project')
  .props({
    id: types.maybeNull(types.identifierNumber),

    inforMyProject: types.maybeNull(MyProjectDetail)
  })

const ProjectsStore = Model.named('ProjectsStore')
  .props({
    listProjects: types.model({
      page: types.number,
      total: types.number,
      items: types.array(Project)
    }),
    recruitingProject: types.maybeNull(types.number)
  })
  .actions(self => ({
    getListProjects(payload) {
      return self.request({
        type: TYPES.GET_LIST_PROJECTS,
        api: getListProjects,
        payload,
      })
    },

    getAboutProjects(payload) {
      return self.request({
        type: TYPES.GET_ABOUT_PROJECTS,
        api: getAboutProjects,
        payload,
        onSuccess: (result) => {
          self.recruitingProject = result.recruitingProject || 0
        },
      })
    },


    getListProjectsRecruiting(payload) {
      return self.request({
        type: TYPES.GET_LIST_PROJECTS_RECRUITING,
        api: getListProjectsRecruiting,
        payload,
      })
    },

    createProject(payload) {
      return self.request({
        type: TYPES.CREATE_PROJECT,
        api: createProject,
        payload,
      })
    },

    getProjectById(payload) {
      return self.request({
        type: TYPES.GET_PROJECT_BY_ID,
        api: getProjectById,
        payload,
      })
    },

    getProjectRecruitingById(payload) {
      return self.request({
        type: TYPES.GET_PROJECT_RECRUITING_BY_ID,
        api: getProjectRecruitingById,
        payload,
      })
    },

    updateProject(payload) {
      return self.request({
        type: TYPES.UPDATE_PROJECT,
        api: updateProject,
        payload,
      })
    },

    joinRequestProject(payload) {
      return self.request({
        type: TYPES.JOIN_REQUEST_PROJECT,
        api: joinRequestProject,
        payload,
      })
    },

    getListProjectMemberForOwner(payload) {
      return self.request({
        type: TYPES.GET_LIST_PROJECT_MEMBER_FOR_OWNER,
        api: getListProjectMemberForOwner,
        payload,
      })
    },

    getListProjectMemberApply(payload) {
      return self.request({
        type: TYPES.GET_LIST_PROJECT_MEMBER_APPLY,
        api: getListProjectMemberApply,
        payload,
      })
    },

    getProjectMemberForUser(payload) {
      return self.request({
        type: TYPES.GET_LIST_PROJECT_MEMBER_FOR_USER,
        api: getProjectMemberForUser,
        payload,
      })
    },

    userUpdateRequestJoinProject(payload) {
      return self.request({
        type: TYPES.USER_UPDATE_REQUEST_JOIN_PROJECT,
        api: userUpdateRequestJoinProject,
        payload,
      })
    },

    getListUserJoined(payload) {
      return self.request({
        type: TYPES.GET_LIST_USER_JOINED,
        api: getListUserJoined,
        payload,
      })
    },

    getListUserOwn(payload) {
      return self.request({
        type: TYPES.GET_LIST_USER_OWN,
        api: getListUserOwn,
        payload,
      })
    },
    ownerUpdateMemberProject(payload) {
      return self.request({
        type: TYPES.OWNER_UPDATE_MEMBER_PROJECT,
        api: ownerUpdateMemberProject,
        payload,
      })
    },
    deleteMemberJoinProject(payload) {
      return self.request({
        type: TYPES.DELETE_PROJECT,
        api: deleteMemberJoinProject,
        payload,
      })
    },
    memberChoiceProject(payload) {
      return self.request({
        type: TYPES.MEMBER_CHOICE_PROJECT,
        api: memberChoiceProject,
        payload,
      })
    },
    memberLeaveProject(payload) {
      return self.request({
        type: TYPES.MEMBER_LEAVE_PROJECT,
        api: memberleaveProject,
        payload,
      })
    },

    getListProjectMessageDetail(payload) {
      return self.request({
        type: TYPES.GET_LIST_PROJECT_MESSAGE_DETAIL,
        api: getListProjectMessageDetail,
        payload,
      })
    },

    addNewMessageByProject(payload) {
      return self.request({
        types: TYPES.ADD_NEW_MESSAGE_BY_PROJECT,
        api: addNewMessageByProject,
        payload
      })
    },

    deleteMessage(payload) {
      return self.request({
        types: TYPES.DELETE_MESSAGE,
        api: deleteMessage,
        payload
      })
    },

    editMessage(payload) {
      return self.request({
        types: TYPES.GET_INFORMATION_USER_CHAT,
        api: editMessage,
        payload
      })
    },

    memberApproveChoiceProject(payload) {
      return self.request({
        type: TYPES.MEMBER_APPROVE_CHOICE_PROJECT,
        api: memberApproveChoiceProject,
        payload,
      })
    },
    memberRejectChoiceProject(payload) {
      return self.request({
        type: TYPES.MEMBER_REJECT_CHOICE_PROJECT,
        api: memberRejectChoiceProject,
        payload,
      })
    },
    ownerDeleteProject(payload) {
      return self.request({
        type: TYPES.OWNER_DELETE_PROJECT,
        api: ownerDeleteProject,
        payload,
      })
    },

    getProjectThreadMessage(payload) {
      return self.request({
        type: TYPES.GET_PROJECT_THREAD_MESSAGE,
        api: getProjectThreadMessage,
        payload,
      })
    },

    getMylistProject(payload) {
      return self.request({
        type: TYPES.GET_MY_LIST_PROJECT,
        api: getMylistProject,
        payload
      })
    },
    getMyProjectDetail(payload) {
      return self.request({
        type: TYPES.GET_MY_PROJECT_DETAILD,
        api: getMyProjectDetail,
        payload
      })
    },
    getListMemberByMyProjectId(payload) {
      return self.request({
        type: TYPES.GET_LIST_MEMBER_BY_MY_PROJECT_ID,
        api: getListMemberByMyProjectId,
        payload
      })
    },
    getStatisticMyProjects(payload) {
      return self.request({
        type: TYPES.GET_STATISTIC_MY_PROJECTS,
        api: getStatisticMyProjects,
        payload
      })
    },
    ownerRejectMemberApply(payload) {
      return self.request({
        type: TYPES.OWNER_REJECT_MEMBER_APPLY,
        api: ownerRejectMemberApply,
        payload
      })
    },
    ownerApproveMemberApply(payload) {
      return self.request({
        type: TYPES.OWNER_APPROVE_MEMBER_APPLY,
        api: ownerApproveMemberApply,
        payload
      })
    },
    createWalletForProject(payload) {
      return self.request({
        type: TYPES.CREATE_WALLET_FOR_PROJECT,
        api: createWalletForProject,
        payload
      })
    },

    ownerChoiceMembersForProject(payload) {
      return self.request({
        type: TYPES.OWNER_CHOICE_MEMBERS_FOR_PROJECT,
        api: ownerChoiceMembersForProject,
        payload
      })
    },

    removeMember(payload) {
      return self.request({
        type: TYPES.REMOVE_MEMBER,
        api: removeMember,
        payload
      })
    },

    createCollectionInSmartContract(payload) {
      return self.request({
        type: TYPES.CREATE_COLLECTION_IN_SMART_CONTRACT,
        api: createCollectionInSmartContract,
        payload
      })
    },

    ownerUpdateProjectMemberRate(payload) {
      return self.request({
        type: TYPES.OWNER_UPDATE_PROJECT_MEMBER_RATE,
        api: ownerUpdateProjectMemberRate,
        payload
      })
    },

    requestToRecruiting(payload) {
      return self.request({
        type: TYPES.REQUEST_TO_RECRUITING,
        api: requestToRecruiting,
        payload
      })
    },

    userApproveNewRate(payload) {
      return self.request({
        type: TYPES.USER_APPROVE_NEW_RATE,
        api: userApproveNewRate,
        payload
      })
    },

    userRejectNewRate(payload) {
      return self.request({
        type: TYPES.USER_REJECT_NEW_RATE,
        api: userRejectNewRate,
        payload
      })
    },

    requestReRecruiting(payload) {
      return self.request({
        type: TYPES.REQUEST_RE_RECRUITING,
        api: requestReRecruiting,
        payload
      })
    },

    updateRequestReRecruiting(payload) {
      return self.request({
        type: TYPES.UPDATE_REQUEST_RE_RECRUITING,
        api: updateRequestReRecruiting,
        payload
      })
    },

    memberRequestLeaveProject(payload) {
      return self.request({
        type: TYPES.MEMBER_REQUEST_LEAVE_PROJECT,
        api: memberRequestLeaveProject,
        payload
      })
    },

    ownerRejectMemberLeaveProject(payload) {
      return self.request({
        type: TYPES.OWNER_REJECT_MEMBER_LEAVE_PROJECT,
        api: ownerRejectMemberLeaveProject,
        payload
      })
    },

    ownerApproveMemberLeaveProject(payload) {
      return self.request({
        type: TYPES.OWNER_APPROVE_MEMBER_LEAVE_PROJECT,
        api: ownerApproveMemberLeaveProject,
        payload
      })
    },

    memberApproveStopProject(payload) {
      return self.request({
        type: TYPES.MEMBER_APPROVE_STOP_PROJECT,
        api: memberApproveStopProject,
        payload
      })
    },

    memberRejectStopProject(payload) {
      return self.request({
        type: TYPES.MEMBER_REJECT_STOP_PROJECT,
        api: memberRejectStopProject,
        payload
      })
    },
    reUpdateContract(payload) {
      return self.request({
        type: TYPES.RE_UPDATE_CONTRACT,
        api: reUpdateContract,
        payload
      })
    },

    createProjectStoreRakuza(payload) {
      return self.request({
        type: TYPES.CREATE_PROJECT_STORE_RAKUZA,
        api: createProjectStoreRakuza,
        payload
      })
    },

    addContractManagementWithRoleOperator(payload) {
      return self.request({
        type: TYPES.ADD_CONTRACT_MANAGEMENT_WITH_ROLE_OPERATOR,
        api: addContractManagementWithRoleOperator,
        payload
      })
    },
    paymentFeeForSystemToActiveStore(payload) {
      return self.request({
        type: TYPES.PAYMENT_FEE_FOR_SYSTEM_TO_ACTIVE_STORE,
        api: paymentFeeForSystemToActiveStore,
        payload
      })
    },


  }))

export { TYPES };

export default ProjectsStore.create({
  listProjects: {
    page: 0,
    total: 0,
    items: []
  }
});
