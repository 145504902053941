import { MainApi } from './endpoint';

export function getAboutProjects(payload) {
  return MainApi.get('/user/project/total-recruiting', payload);
}

export function getListProjects(payload) {
  return MainApi.get('/user/project', payload);
}

export function getListProjectsRecruiting(payload) {
  return MainApi.get('/user/project/recruiting', payload);
}

export function createProject(payload) {
  return MainApi.post('/user/project', payload);
}

export function getProjectById({ id, ...payload }) {
  return MainApi.get(`/user/project/${id}`, payload);
}

export function getProjectRecruitingById({ id, ...payload }) {
  return MainApi.get(`/user/project/recruiting/${id}`, payload);
}

export function updateProject({ id, ...payload }) {
  return MainApi.put(`/user/project/${id}`, payload);
}

export function joinRequestProject({ id, ...payload }) {
  return MainApi.post(`/user/project/${id}/join-request`, payload);
}

export function getListProjectMemberForOwner({ id, ...payload }) {
  return MainApi.get(`/user/project/${id}/member`, payload);
}

export function getListProjectMemberApply({ id, ...payload }) {
  return MainApi.get(`/user/project/${id}/member-apply`, payload);
}

export function getProjectMemberForUser({ id, userId, ...payload }) {
  return MainApi.get(`/user/project/${id}/member/${userId}`, payload);
}

export function userUpdateRequestJoinProject({ id, ...payload }) {
  return MainApi.put(`/user/project/${id}/update-join-request`, payload);
}

export function getListUserJoined(payload) {
  return MainApi.get(`/user/project/joined`, payload);
}

export function getListUserOwn(payload) {
  return MainApi.get(`/user/project/own`, payload);
}

export function ownerUpdateMemberProject({ projectId, memberId, ...payload }) {
  return MainApi.put(`/user/project/${projectId}/edit-member/${memberId}`, payload);
}

export function deleteMemberJoinProject({ projectId, memberId, ...payload }) {
  return MainApi.delete(`/user/project/${projectId}/member/${memberId}`, payload);
}

export function memberChoiceProject({ id, ...payload }) {
  return MainApi.post(`/user/project/${id}/choice`, payload);
}

export function memberleaveProject({ projectId, ...payload }) {
  return MainApi.delete(`/user/project/${projectId}/leave`, payload);
}

export function getListProjectMessageDetail({ id, msgThreadId, ...payload }) {
  return MainApi.get(`/user/project/${id}/message/${msgThreadId}`, payload);
}

export function addNewMessageByProject({ id, ...payload }) {
  return MainApi.post(`/user/project/${id}/message`, payload);
}

export function deleteMessage({ id, msgId }) {
  return MainApi.delete(`/user/project/${id}/message/${msgId}`)
}

export function editMessage({ id, msgId, ...payload }) {
  return MainApi.put(`/user/project/${id}/message/${msgId}`, payload)
}

export function memberApproveChoiceProject({ projectId, ...payload }) {
  return MainApi.put(`/user/project/${projectId}/approve-choice`, payload);
}

export function memberRejectChoiceProject({ projectId, ...payload }) {
  return MainApi.put(`/user/project/${projectId}/reject-choice`, payload);
}

export function ownerDeleteProject({ projectId, ...payload }) {
  return MainApi.delete(`/user/project/${projectId}`, payload);
}

export function getProjectThreadMessage({ id, projectOwnerId, userId, ...payload }) {
  return MainApi.get(`/user/project/${id}/thread-message/${projectOwnerId}/${userId}`, payload);
}

export function getMylistProject(payload) {
  return MainApi.get('/user/project', payload)
}

export function getMyProjectDetail({ id, ...payload }) {
  return MainApi.get(`/user/project/${id}`, payload)
}

export function getListMemberByMyProjectId({ id, ...payload }) {
  return MainApi.get(`/user/project/${id}/member`, payload)
}

export function getStatisticMyProjects() {
  return MainApi.get('/user/project/statistic')
}

export function ownerRejectMemberApply({ id }) {
  return MainApi.delete(`/user/project/member/${id}`)
}

export function ownerApproveMemberApply({ id }) {
  return MainApi.put(`/user/project/member/${id}`)
}

export function createWalletForProject({ id }) {
  return MainApi.post(`/user/project/${id}/create-wallet`)
}

export function ownerChoiceMembersForProject({ id, ...payload }) {
  return MainApi.post(`/user/project/${id}/choice-member`, payload)
}

export function removeMember({ id }) {
  return MainApi.delete(`/user/project/member/${id}`)
}

export function createCollectionInSmartContract({ id }) {
  return MainApi.post(`/user/project/${id}/create-collection`)
}

export function ownerUpdateProjectMemberRate({ id, ...payload }) {
  return MainApi.put(`/user/project/${id}/edit-member-rate`, payload)
}

export function requestToRecruiting({ id }) {
  return MainApi.put(`/user/project/${id}/to-recruiting`)
}

export function userApproveNewRate({ id }) {
  return MainApi.put(`/user/project/${id}/approve-new-rate`)
}

export function userRejectNewRate({ id }) {
  return MainApi.put(`/user/project/${id}/reject-new-rate`)
}

export function requestReRecruiting({ id, ...payload }) {
  return MainApi.post(`/user/project/${id}/re-recruiting`, payload)
}

export function updateRequestReRecruiting({ id }) {
  return MainApi.put(`/user/project/${id}/re-recruiting`)
}

export function memberRequestLeaveProject({ id }) {
  return MainApi.put(`/user/project/${id}/leave`)
}

export function ownerRejectMemberLeaveProject({ projectId, projectMemberId }) {
  return MainApi.put(`/user/project/${projectId}/reject-request-leaving-project/${projectMemberId}`)
}

export function ownerApproveMemberLeaveProject({ projectId, projectMemberId }) {
  return MainApi.put(`/user/project/${projectId}/approve-request-leaving-project/${projectMemberId}`)
}

export function memberApproveStopProject({projectId}) {
  return MainApi.put(`/user/project/${projectId}/approve-stop`)
}

export function memberRejectStopProject({projectId}) {
  return MainApi.put(`/user/project/${projectId}/reject-stop`)
}

export function reUpdateContract({projectId}) {
  return MainApi.put(`/user/project/${projectId}/recall-updating-reward-rate-contract`)
}

export function createProjectStoreRakuza({projectId, ...payload}) {
  return MainApi.post(`/user/project/${projectId}/project-store-rakuza`, payload)
}

export function paymentFeeForSystemToActiveStore({projectId}) {
  return MainApi.post(`/user/project/${projectId}/request-payment-store-package`)
}

export function addContractManagementWithRoleOperator({projectId}) {
  return MainApi.post(`/user/project/${projectId}/add-operator-contract-project`)
}