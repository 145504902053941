import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { inject, observer } from 'mobx-react';

import Request from '../utils/request';
import Storage from '../utils/storage';
import authStore from '../store/auth';
import Web3 from '../utils/web3other';
import i18n from '../translations/i18n';
import { logout } from '../utils/auth';
import Misc from '../utils/misc';
import { Web3AuthContext } from '@/contexts/web3auth/web3auth'

yup.setLocale({
  mixed: {
    required: 'required',
  },
  string: {
    email: 'email',
  },
});

@inject(stores => ({
  productsStore: stores.products,
  notificationsStore: stores.notifications
}))

class Init extends Component {
  static propTypes = {
    productsStore: PropTypes.object,
    initedSuccess: PropTypes.bool
  };

  state = {
    inited: false,
  };

  async componentDidMount() {
    const { productsStore } = this.props;

    const token = Storage.get('ACCESS_TOKEN');
    const addressIsLogged = Storage.get('PUBLIC_ADDRESS');
    const userId = Storage.get('USER_ID');
    
    if (Storage.get('LONGIN_BY') === 'METAMASK' && Storage.get('LONGIN_BY') !== 'SOCIAL') {
      const web3Info = await Web3?.getWeb3Instance();
      let web3;
      let accounts;

      if (web3Info) {
        web3 = web3Info?.web3;
        if (!Misc.isMobile) {
          accounts = await web3?.eth?.getAccounts();
          if (accounts?.length > 0) {
            await web3?.currentProvider?.enable();
          }
        }

        if (token && accounts?.length <= 0) {
          logout();
        }

        if (!Misc.isMobile) {
          web3?.currentProvider?.on('accountsChanged', () => {
            this._handleLogout();
          });

          web3?.currentProvider?.on('chainChanged', () => {
            this._handleLogout();
          });

          web3?.currentProvider?.on('disconnect', () => {
            this._handleLogout();
          });
        }
      }

      if (window.location.pathname.slice(0, 3) === '/en') {
        i18n.changeLanguage('en');
      }

      const priceRateResult = await productsStore.getPriceRate();

      if (priceRateResult.success) {
        const usdToJpy = +priceRateResult.data.find(item => item.name === 'USD_TO_JPY').value;
        const gokuToJpy = +priceRateResult.data.find(item => item.name === 'GOKU_TO_JPY').value;
        const ethToGoku = +priceRateResult.data.find(item => item.name === 'ETH_TO_GOKU').value;
        const nbngToGoku = +priceRateResult.data.find(item => item.name === 'NBNG_TO_GOKU').value;

        authStore.setInitialData({
          userId,
          usdToJpy,
          gokuToJpy,
          ethToGoku,
          nbngToGoku,
        });
      }

      if (token) {
        Request.setAccessToken(token);
        authStore.setLoggedIn(true);
        const { success: initSuccess, data: initData } = await authStore.getInitialData();

        if (initSuccess) {
          Storage.set('MEMBER_FLAG', initData?.memberFlg)
        }

        await authStore.getBalanceNftByTokenId()
      }

      this.setState({ inited: true });

      if (!token) return;

      try {
        await web3?.currentProvider?.enable();

        if (token && addressIsLogged) {
          authStore.setInitialData({
            publicAddress: addressIsLogged,
            userId,
          });

          const { eth, weth, usdt } = await Web3.getBalance(addressIsLogged);
          
          authStore.setBalance({
            eth: eth > 0.0001 ? eth : 0,
            usdt: usdt || 0,
            weth: weth || 0,
          });

          if (Misc.isMobile) {
            web3?.currentProvider?.on('accountsChanged', () => {
              this._handleLogout();
            });

            web3?.currentProvider?.on('chainChanged', () => {
              this._handleLogout();
            });

            web3?.currentProvider?.on('disconnect', () => {
              this._handleLogout();
            });
          }

        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    } else {
      this.setState({ inited: true });
    }
  }

  _handleLogout = async () => {
    const tokenChange = Storage.get('ACCESS_TOKEN');
    const userIdChange = Storage.get('USER_ID');

    if (tokenChange && userIdChange) {
      await logout();
    }

  };

  render() {
    const { children, initedSuccess } = this.props;
    const { inited } = this.state;
    return inited || initedSuccess ? children : null;
  }
}


function withMyHook(Component) {

  const WrappedComponent = ({ authStore, productsStore, provider, getBalance, getChainId, ...props }) => {

    const [initedSuccess, setInitedSuccess] = React.useState(false)
    React.useEffect(() => {

      if (Storage.get('LONGIN_BY') === 'SOCIAL') {
        (async () => {

          const token = Storage.get('ACCESS_TOKEN');
          const publicAddress = Storage.get('PUBLIC_ADDRESS')
          Request.setAccessToken(token);
          const hasAccessToken = localStorage.getItem('ACCESS_TOKEN') !== null;
          const userId = Storage.get('USER_ID');

          const balance = await getBalance();
          const usdtBalance = await provider?.getBalanceUsdt();
          const wethBalance = await provider?.getBalanceWeth();

          if (hasAccessToken) {
            const priceRateResult = await productsStore.getPriceRate();
            if (priceRateResult.success) {
              const usdToJpy = +priceRateResult.data.find(item => item.name === 'USD_TO_JPY').value;
              const gokuToJpy = +priceRateResult.data.find(item => item.name === 'GOKU_TO_JPY').value;
              const ethToGoku = +priceRateResult.data.find(item => item.name === 'ETH_TO_GOKU').value;
              const nbngToGoku = +priceRateResult.data.find(item => item.name === 'NBNG_TO_GOKU').value;

              authStore.setInitialData({
                userId,
                usdToJpy,
                gokuToJpy,
                ethToGoku,
                nbngToGoku,
                publicAddress
              });
              await authStore.getInitialData();
              authStore.setLoggedIn(true);

              await authStore.getBalanceNftByTokenId()
              await authStore.setBalance({
                eth: balance || 0,
                usdt: usdtBalance || 0,
                weth: wethBalance || 0
              });
            }

            setInitedSuccess(true)
          }


        })()
      }

    }, [authStore, getBalance, productsStore, provider])

    return <Component
      {...props}
      initedSuccess={initedSuccess}
    />
  };

  return inject(stores => ({
    authStore: stores.auth,
    productsStore: stores.products
  }))(
    observer(({ authStore, productsStore, ...props }) => {
      const {
        getBalance,
        provider,
        getChainId
      } = useContext(Web3AuthContext)

      return (
        <WrappedComponent
          {...props}
          authStore={authStore}
          productsStore={productsStore}
          getBalance={getBalance}
          provider={provider}
          getChainId={getChainId}
        />
      );
    }),
  );
}

export default withMyHook(Init)