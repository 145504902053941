import { types } from 'mobx-state-tree';

import { Model } from '../utils/mobx-model-helper';

import {
  getNotificationsStatistic,
  getListNotifications,
  getNotificationDetail,
  getCommentsByNotiId,
  commentInNotification,
  updateCommentInNotification,
  deleteCommentInNotification,
  getListNotificationsPinned,
  getListLowComments,
  commentInCommentNotification,
  getListNotificationsActive,
  updateNotificationsActivity,
  approveChoiceMember
} from '../api/notifications';

const TYPES = {
  GET_NOTIFICATIONS_STATISTIC: 1,
  GET_LIST_NOTIFICATIONS: 2,
  GET_NOTIFICATION_DETAIL: 3,
  GET_COMMENTS_BY_NOTI_ID: 4,
  COMMENT_IN_NOTIFICATION: 5,
  UPDATE_COMMENT_INNOTIFICATION: 6,
  DELETE_COMMENT_IN_NOTIFICATION: 7,
  GET_LIST_NOTIFICATIONS_PINNED: 8,
  GET_LIST_LOW_COMMENTS: 9,
  COMMENT_IN_COMMENT_NOTIFICATION: 10,
  GET_LIST_NOTIFICATIONS_ACTIVE: 11,
  UPDATE_NOTIFICATIONS_ACTIVITY: 12,
  APPROVE_CHOICE_MEMBER: 13
};

const Notification = types.model('Notification')
  .props({
    id: types.number,
    category: types.maybeNull(types.string),
    sender: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    sendDate: types.maybeNull(types.number),
    hotFlg: types.maybeNull(types.boolean),
    pinnedFlg: types.maybeNull(types.boolean),
    imageUrl: types.maybeNull(types.string),
    commentCount: types.maybeNull(types.number)
  })

const Comment = types.model('Comment')
  .props({
    id: types.maybeNull(types.number),
    content: types.maybeNull(types.string),
    sendDate: types.maybeNull(types.number),
    sender: types.maybeNull(types.string)
  })
  .actions(self => ({
    setComment(payload) {
      self.content = payload.content
    }

  }))

const PostNoti = types.model('PostNoti')
  .props({
    id: types.maybeNull(types.number),
    category: types.maybeNull(types.string),
    sender: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    imageUrl: types.maybeNull(types.string),
    hotFlg: types.maybeNull(types.boolean),
    pinnedFlg: types.maybeNull(types.boolean),
    sendDate: types.maybeNull(types.number),
    content: types.maybeNull(types.string),
    commentCount: types.maybeNull(types.number)
  })

  const NotificationDTO = types.model('NotificationDTO')
  .props({
    category: types.maybeNull(types.string),
    commentCount: types.maybeNull(types.number),
    hotFlg: types.maybeNull(types.boolean),
    id: types.maybeNull(types.number),
    imageUrl: types.maybeNull(types.string),
    importantFlg: types.maybeNull(types.boolean),
    notificationId: types.maybeNull(types.number),
    notificationSender: types.maybeNull(types.string),
    notificationTitle: types.maybeNull(types.string),
    pinnedFlg: types.maybeNull(types.boolean),
    sendDate: types.maybeNull(types.number),
    sender: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    code: types.maybeNull(types.string)
  })

  const ProjectDto = types.model('ProjectDto')
  .props({
    projectId: types.maybeNull(types.number),
    projectOwner: types.maybeNull(types.string),
    projectOwnerId: types.maybeNull(types.number),
    projectTitle: types.maybeNull(types.string),
    code: types.maybeNull(types.string)
  })
  const SuggestionDto = types.model('SuggestionDto')
  .props({
    note: types.maybeNull(types.string),
    suggestionId: types.maybeNull(types.number),
    suggestionOwner: types.maybeNull(types.string),
    suggestionOwnerId: types.maybeNull(types.number),
    suggestionTitle: types.maybeNull(types.string),
    code: types.maybeNull(types.string),
    status: types.maybeNull(types.string)
  })

const NotificationActive = types.model('NotificationActive')
  .props({
    activityId: types.maybeNull(types.number),
    activityType: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.number),
    expiredAt: types.maybeNull(types.number),
    notificationDto: types.maybeNull(NotificationDTO),
    projectDto: types.maybeNull(ProjectDto),
    readFlg: types.maybeNull(types.boolean),
    suggestionDto: types.maybeNull(SuggestionDto),
    userId: types.maybeNull(types.number),
    userName: types.maybeNull(types.string),
    activityStatus: types.maybeNull(types.string)
  })
  .actions(self => ({
    setRead() {
      self.read = true
    }
  }))

const NotificationStore = Model.named('NotificationStore')
  .props({
    countNoti: types.model({
      unread: types.maybeNull(types.number)
    }),
    notifications: types.model({
      page: types.number,
      total: types.number,
      items: types.array(Notification)
    }),
    notificationDetail: PostNoti,

    commentsById: types.model({
      page: types.number,
      total: types.number,
      items: types.array(Comment),
      pages: types.number
    }),

    listLowComments: types.model({
      page: types.number,
      total: types.number,
      items: types.array(Comment),
      pages: types.number
    }),

    notificationsActivity: types.model({
      page: types.number,
      total: types.number,
      items: types.array(NotificationActive),
      pages: types.number
    })
  })
  .actions(self => ({
    getNotificationsStatistic() {
      return self.request({
        type: TYPES.GET_NOTIFICATIONS_STATISTIC,
        api: getNotificationsStatistic,
        onSuccess: result => {
          self.countNoti.unread = result.unread
        },
      })
    },

    getListNotifications(payload) {
      return self.request({
        type: TYPES.GET_LIST_NOTIFICATIONS,
        api: getListNotifications,
        payload,
        onSuccess: (result) => {
          self.notifications.page = result.page
          self.notifications.total = result.total
          self.notifications.items = result.result
        },
      })
    },

    getListNotificationsPinned() {
      return self.request({
        type: TYPES.GET_LIST_NOTIFICATIONS_PINNED,
        api: getListNotificationsPinned
      })
    },


    getNotificationDetail(payload) {
      return self.request({
        type: TYPES.GET_NOTIFICATION_DETAIL,
        api: getNotificationDetail,
        payload,
        onSuccess: (result) => {
          self.notificationDetail = result
        }
      })
    },

    getCommentsByNotiId(payload, concat) {
      return self.request({
        type: TYPES.GET_COMMENTS_BY_NOTI_ID,
        api: getCommentsByNotiId,
        payload,
        onSuccess: (result) => {
          self.commentsById.page = result.page
          self.commentsById.total = result.total
          self.commentsById.items = concat ? self.commentsById.items.concat(result.result) : result.result
          self.commentsById.pages = result.pages
        }
      })
    },

    commentInNotification(payload) {
      return self.request({
        type: TYPES.COMMENT_IN_NOTIFICATION,
        api: commentInNotification,
        payload,
        onSuccess: (result) => {
          self.commentsById.items = self.commentsById.items.concat(result)
        }
      })
    },

    getListLowComments(payload, concat) {
      return self.request({
        type: TYPES.GET_LIST_LOW_COMMENTS,
        api: getListLowComments,
        payload,
        onSuccess: (result) => {
          self.commentsById.page = result.page
          self.commentsById.total = result.total
          self.commentsById.items = concat ? self.commentsById.items.concat(result.result) : result.result
          self.commentsById.pages = result.pages
        }
      })
    },

    commentInCommentNotification(payload) {
      return self.request({
        type: TYPES.COMMENT_IN_COMMENT_NOTIFICATION,
        api: commentInCommentNotification,
        payload,
      })
    },

    updateCommentInNotification(payload) {
      return self.request({
        type: TYPES.COMMENT_IN_NOTIFICATION,
        api: updateCommentInNotification,
        payload,
      })
    },

    deleteCommentInNotification(payload) {
      return self.request({
        type: TYPES.DELETE_COMMENT_IN_NOTIFICATION,
        api: deleteCommentInNotification,
        payload,
        onSuccess: () => {
          const objEdit = self.commentsById.items.filter(item => item.id !== payload.commentId)

          self.commentsById.items = objEdit
        }
      })
    },

    getListNotificationsActive(payload, concat) {
      return self.request({
        type: TYPES.GET_LIST_NOTIFICATIONS_ACTIVE,
        api: getListNotificationsActive,
        payload,
        onSuccess: (res) => {
          self.notificationsActivity.page = res.page
          self.notificationsActivity.total = res.total
          self.notificationsActivity.items = concat ? self.notificationsActivity.items.concat(res.result) : res.result
          self.notificationsActivity.pages = res.pages
        }
      })
    },

    updateNotificationsActivity(payload) {
      return self.request({
        type: TYPES.UPDATE_NOTIFICATIONS_ACTIVITY,
        api: updateNotificationsActivity,
        payload
      })
    },

    approveChoiceMember(payload) {
      return self.request({
        type: TYPES.APPROVE_CHOICE_MEMBER,
        api: approveChoiceMember,
        payload
      })
    }

  }))

export { TYPES };

export default NotificationStore.create({
  countNoti: {
    unread: 0
  },
  notifications: {
    page: 0,
    total: 0,
    items: []
  },
  notificationDetail: {
    id: null,
    category: null,
    sender: null,
    title: null,
    imageUrl: null,
    hotFlg: null,
    pinnedFlg: null,
    sendDate: null,
    content: null,
    commentCount: null
  },

  commentsById: {
    page: 0,
    total: 0,
    items: [],
    pages: 0
  },

  listLowComments: {
    page: 0,
    total: 0,
    items: [],
    pages: 0
  },

  notificationsActivity: {
    page: 0,
    total: 0,
    items: [],
    pages: 0
  }
});