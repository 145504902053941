/* eslint-disable */
import { Component, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MobXProviderContext, inject, observer } from 'mobx-react';
import lodash from 'lodash';
import { withRouter, useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Menu, Dropdown, Popover, Spin } from 'antd';
import { BellOutlined } from '@ant-design/icons';

import Button from '../components/button';
import Typography from '../components/typography';
import Clickable from '../components/clickable';
import Thumbnail from '../components/thumbnail';
import Input from '../components/input';
import { Images, Colors } from '../theme';
import Misc from '../utils/misc';
import Format from '../utils/format';
import Media from '../utils/media';
import { events } from '../services';
import { logout } from '../utils/auth';
import LoginModal from './login-modal';
import { useWeb3Auth } from '../contexts/web3auth/web3auth';
import ModalNotification from '@/app/modal-notification';
import ModalLogin from './login';
import Toast from '@/components/toast';
import ManualPdf from '@/resources/images/top/manual.pdf';

const HeaderContainer = styled.header`
  ${Media.lessThan(Media.SIZE.XXL)} {
  }

  ${Media.lessThan(Media.SIZE.MD)} {
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    .gnav-main {
      li {
        a {
          span {
            font-size: 1rem;
          }
        }
      }
    }
  }
`;
const StyledPopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  a {
    margin-bottom: 10px;
    user-select: none;
    color: ${Colors.BLACK};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const FlagItemBox = styled(Clickable)`
  display: flex;
  align-items: center;
  font-weight: bold;

  img {
    margin-top: 2px;
    width: 20px;
    margin-right: 5px;
    box-shadow: 0px 0px 3px 1px #00000021;
  }
`;
const BlockNotification = styled.div`
  position: relative;
  /* margin: 0px 14px 0px 10px; */
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  .count {
    position: absolute;
    background-color: ${Colors.RED};
    color: #fff;
    border-radius: 40px;
    width: 21px;
    height: 21px;
    font-size: 10px;
    font-weight: 500;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -6px;
    top: -6px;
  }

  span {
    cursor: pointer;
    transition: 0.3s;
    svg {
      width: 24px;
      height: 24px;
    }
    &:hover {
      opacity: 0.7;
    }
  }
`;

const BlockImage = styled.span`
  &::after {
    background-image: url(${props => props.image || ''}) !important;
  }
`;

const Button2 = styled.a`
  &.btn-2 {
    border-radius: initial;
    border: none;
    padding: 0;
    background-color: transparent;

    &:hover {
      span {
        color: #da1d31;
        opacity: 0.5;
      }
      background: transparent;
    }
  }
`;

const ButtonWrap = styled.div`
  &.has-login {
    /* background-color: #f0f0f0; */
    padding-right: 8px;
    ._left-box {
      min-width: 115px;
      .small {
        font-size: 10px;
      }

      ._horizotal {
        > * {
          &:first-child {
            margin-right: 2px;
            font-size: 10px;
          }

          &:last-child {
            margin-bottom: 2px;
            font-size: 11px;
          }
        }
      }

      p {
        color: #000000;
        font-size: 12px;
      }
    }
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    &.has-login {
      background-color: #f0f0f0;
      justify-content: flex-start;
      padding-right: 0;
      padding-top: 13px;
      justify-content: space-around;
    }
  }
`;

const ClickableStyled = styled(Clickable)`
  &.user-box {
    display: flex;
    height: 47px;

    ._avatar-box {
      ._dot {
        top: -4px;
        left: -4px;
      }
      margin-left: 1px;

      .avatar-image {
        /* width: 27px;
        height: 27px; */
      }
    }
  }
`;

@withTranslation('common')
@withRouter
@inject(stores => ({
  authStore: stores.auth,
  usersStore: stores.users,
  productsStore: stores.products,
  notificationsStore: stores.notifications,
}))
@observer
class Header extends Component {
  static propTypes = {
    authStore: PropTypes.object,
    location: PropTypes.object,
    productsStore: PropTypes.object,
    notificationsStore: PropTypes.object,
    loggedIn: PropTypes.bool
  };

  state = {
    isOpenMenuPanel: false,
    searchType: 'ALL',
    previousPathName: '',
    isOpenPopover: false,
    isOpenModalNotification: false,
  };

  async componentDidMount() {
    const { authStore } = this.props;
    await authStore.fetchPrefecture({});
  }

  _onLogout = e => {
    const { location } = window;
    const { pathname } = location;

    e.preventDefault();
    if (pathname.includes('/category/rakuzadao_exchange/')) {
      logout(true);
    } else {
      logout();
    }

    this.setState({
      isOpenMenuPanel: false,
      isOpenPopover: false,
    });

    if (this.props.web3Auth.connected) {
      this.props.web3Auth.logout();
    }
  };

  _onOpenMyPage = async e => {
    const { history, authStore } = this.props;
    e.preventDefault();
    const { success } = await authStore.getInitialData()

    this.setState({
      isOpenMenuPanel: false,
      isOpenPopover: false,
    });
    if ( success ) {
      history.push('/my-page');
    }
  };

  _onOpenMyPageIssuing = e => {
    e.preventDefault();
    const { history } = this.props;
    history.push('/issuing-nft/manager-nft');
    this.setState({ isOpenMenuPanel: false, isOpenPopover: false });
  };

  _onLoginButtonClick = () => {
    LoginModal.open();
  };

  _onBackHome = () => {
    const { history, productsStore } = this.props;

    productsStore.newProducts.reverseLoaded();
    productsStore.regenerateRandomNumber();

    history.push('/');
    this.setState({ isOpenMenuPanel: false });
  };

  _onToggleMenuPanel = () => {
    this.setState(state => ({
      isOpenMenuPanel: !state.isOpenMenuPanel,
    }));
  };

  _onChangeLanguage = language => {
    const { i18n, location, history } = this.props;

    i18n.changeLanguage(language, () => {
      let newPath = location.pathname.replace('/en/', '/');
      newPath = newPath.replace('/ja/', '/');

      history.replace(newPath);
    });
  };

  _onSearchChange = value => {
    const { history, location } = this.props;
    const { searchType, previousPathName } = this.state;
    value = value.target.value.trim();

    if (location.pathname !== '/search/keyword' && value.trim()) {
      history.push(`/search/keyword/${value}`);
    }
    if (!value.trim()) {
      history.replace(previousPathName);
    }
    if (value.trim()) {
      this._searchDebounce({
        value: value.trim(),
        searchType,
      });
    }
  };

  _handleSavePathLocation = () => {
    const { location } = this.props;
    if (!location.pathname.includes('/search/keyword')) {
      this.setState({
        previousPathName: location.pathname.slice(3),
      });
    }
  };

  _searchDebounce = lodash.debounce(value => {
    events.headerSearchChange.emit(value);
  }, 300);

  _showValueSearch = () => {
    const { location, i18n } = this.props;

    if (location.pathname.includes('/search/keyword/')) {
      const path = location.pathname;
      const { language } = i18n;

      return path.replace(`/${language}/search/keyword/`, '');
    }
  };

  _onCurrencySelect = currency => {
    const { authStore } = this.props;
    authStore.setCurrency(currency);

    events.topCurrencyChange.emit(currency);
  };

  _onMenuPanelClick = item => {
    const { history } = this.props;

    if (item.path) {
      history.push(item.path);
    } else if (item.mailto) {
      window.location.href = `mailto:${item.mailto}`;
    } else {
      window.open(item.url, '_self');
    }

    this.setState({ isOpenMenuPanel: false });
  };

  _onChangeSearchType = type => {
    this.setState({
      searchType: type,
    });
  };

  _renderMenuPanel = () => {
    const { t } = this.props;
    const { isOpenMenuPanel } = this.state;

    if (!isOpenMenuPanel) return null;

    const MENU_1 = [
      {
        name: 'TOP',
        path: '/',
      },
      {
        name: t('footer.about_rakuza.about_rakuza'),
        url: 'https://rakuza.io/about/index.html',
      },
      {
        name: t('footer.about_rakuza.instruction'),
        url: 'https://rakuza.io/guide/index.html',
      },
      {
        name: t('footer.about_rakuza.event_info'),
        url: 'https://rakuza.io/guide/index.html',
      },
      {
        name: t('footer.about_rakuza.faq'),
        url: 'https://rakuza.io/faq/index.html',
      },
    ];
    const MENU_2 = [
      {
        name: t('footer.about_us.title'),
        items: [
          {
            name: t('footer.about_us.operation'),
            url: 'https://rakuza.io/company/index.html',
          },
          {
            name: t('footer.about_us.contact'),
            mailto: 'info@rakuza.io',
          },
        ],
      },
      {
        name: t('footer.policy.title'),
        items: [
          {
            name: t('footer.policy.notation'),
            url: 'https://rakuza.io/sct/index.html',
          },
          {
            name: t('footer.policy.terms'),
            url: 'https://rakuza.io/terms_of_use/index.html',
          },
          {
            name: t('footer.policy.policy'),
            url: 'https://rakuza.io/policy/index.html',
          },
        ],
      },
    ];

    return (
      <div className="menu-panel">
        <div className="menu-panel-content">
          <div className="side">
            {MENU_1.map((item, index) => (
              <Clickable key={index} className="left-menu-item" onClick={() => this._onMenuPanelClick(item)}>
                <Typography bold>{item.name}</Typography>
              </Clickable>
            ))}
          </div>
          <div className="side">
            {MENU_2.map((parent, index) => (
              <div className="right-menu-group" key={index}>
                <Typography className="right-menu-group-title" bold>
                  {parent.name}
                </Typography>
                {parent.items.map((child, childIndex) => (
                  <Clickable key={childIndex} className="right-menu-item" onClick={() => this._onMenuPanelClick(child)}>
                    <Typography>{child.name}</Typography>
                  </Clickable>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className="language-box-mobile">{this._renderMultiLanguage()}</div>
      </div>
    );
  };

  _renderMultiLanguage = () => {
    const { i18n } = this.props;
    const items = [
      {
        label: (
          <Menu key="en">
            <Menu.Item key="en">
              <FlagItemBox effect={false} onClick={() => this._onChangeLanguage('en')}>
                <img src={Images[`${'en'.toUpperCase()}_FLAG`]} alt="" />
                <Typography bold size="large">
                  EN
                </Typography>
              </FlagItemBox>
            </Menu.Item>
          </Menu>
        ),
        key: 'en',
      }, // remember to pass the key prop
      {
        label: (
          <Menu key="ja">
            <Menu.Item key="ja">
              <FlagItemBox effect={false} onClick={() => this._onChangeLanguage('ja')}>
                <img src={Images[`${'ja'.toUpperCase()}_FLAG`]} alt="" />
                <Typography bold size="large">
                  JA
                </Typography>
              </FlagItemBox>
            </Menu.Item>
          </Menu>
        ),
        key: 'ja',
      },
    ];
    return (
      <Dropdown trigger="click" menu={{ items }}>
        <Clickable className="language-box">
          <img className="flag-icon" src={Images[`${i18n.language.toUpperCase()}_FLAG`]} alt="" />
          <Typography bold size="large">
            {i18n.language === 'en' ? 'EN' : 'JP'}
          </Typography>
          <img className="arrow-icon" src={Images.BLACK_CHEVRON_DOWN_ICON} alt="" />
        </Clickable>
      </Dropdown>
    );
  };

  _onGotoMyPage = () => {
    const { history } = this.props;
    history.push('/my-page/wish-list');
  };

  _openModalNotifyCation = () => {
    const { isOpenModalNotification } = this.state;

    this.setState({
      isOpenModalNotification: !isOpenModalNotification,
    });
  };

  render() {
    const { authStore, location, t, notificationsStore, isOpenModalMember, handleCloseModal } = this.props;
    const { isOpenMenuPanel, isOpenPopover, isOpenModalNotification } = this.state;

    const { countNoti } = notificationsStore;
    const { unread } = countNoti;

    const isShowSearchInput = location.pathname !== '/events' && !location.pathname.includes('product-details');

    return (
      <HeaderContainer className="header" role="banner">
        <p className="header-logo">
          <a href="/">
            <img className="img-h" src={Images.DAOSITE_SITE_LOGO} alt="楽座DAO" />
          </a>
        </p>

        <div className="header-main">
          <div
            className="hamb-btn"
            id="hamb-btn"
            onClick={() => {
              console.log(document.body.className);
              const classesName = document.body.className;

              if (!!classesName.length) {
                document.body.className = '';
              } else {
                document.body.className = 'g-active';
              }
            }}
          >
            <div className="btn-inr">
              <span className="btn-bar"></span>
            </div>
          </div>
          <section className="hamb-menu">
            <nav className="gnav">
              <ul className="gnav-main">
                <li>
                  <a href="#concept">
                    <span>CONCEPT</span>
                  </a>
                </li>
                <li>
                  <a href="#about">
                    <span>ABOUT RAKUZA</span>
                  </a>
                </li>
                <li>
                  <a href="#member">
                    <span>DAO MEMBER</span>
                  </a>
                </li>
                <li>
                  <a href="#system">
                    <span>RBR SYSTEM</span>
                  </a>
                </li>
                <li>
                  <a href="#roadmap">
                    <span>ROAD MAP</span>
                  </a>
                </li>
                <li>
                  <a href="#faq">
                    <span>FAQ</span>
                  </a>
                </li>

              </ul>
              <div className="btn-wrap">
                <a href={ManualPdf} target="_blank" className="btn-2" rel="noreferrer">
                  <BlockImage image={Images.DAOSITE_ICO_MANUAL}>楽座DAO購入マニュアル</BlockImage>
                </a>
              </div>
            </nav>
          </section>

          {authStore.loggedIn ? (
            <ButtonWrap className={`btn-wrap btn-right cvBtn ${authStore.loggedIn ? 'has-login' : ''}`}>
              <BlockNotification unread={unread}>
                <BellOutlined onClick={this._openModalNotifyCation} />
                {/* <count className="count">{unread > 99 ? `99+` : unread}</count> */}
                {isOpenModalNotification ? <ModalNotification hanldleRedirectPage={this._openModalNotifyCation} /> : ''}
              </BlockNotification>
              <div className="_left-box">
                <div className="_horizotal">
                  <Typography bold>
                    {Misc.trimPublicAddress(authStore.initialData.publicAddress || '', 6)}
                  </Typography>
                  <Typography bold>
                    {Format.price(authStore.initialData.ethBalance)} ETH
                  </Typography>
                </div>
              </div>
              <Popover
                open={isOpenPopover}
                trigger="click"
                content={
                  <StyledPopoverContent>
                    <a href="/" onClick={this._onOpenMyPage}>
                      {t('header.my_page')}
                    </a>
                    {authStore.initialData.creator ? (
                      <a href="/" onClick={this._onOpenMyPageIssuing}>
                        {t('header.issuing_nft')}
                      </a>
                    ) : (
                      ''
                    )}
                    <a href="/" onClick={this._onLogout}>
                      {t('header.logout')}
                    </a>
                  </StyledPopoverContent>
                }
                placement={Misc.isMobile ? 'top' : "bottomRight"}
                onClick={() =>
                  this.setState({
                    isOpenPopover: !isOpenPopover,
                  })
                }
              >
                <ClickableStyled className="user-box">
                  <div className="_avatar-box">
                    {/* <div className="_dot" /> */}
                    <Thumbnail
                      rounded
                      size={40}
                      url={authStore.initialData.userImage}
                      className="avatar-image"
                      placeholderUrl={Images.USER_PLACEHOLDER}
                    />
                  </div>
                </ClickableStyled>
              </Popover>
            </ButtonWrap>
          ) : (
            <ButtonWrap className="btn-wrap btn-right cvBtn" onClick={this._onLoginButtonClick}>
              <div className="btn-1">
                <span className="arr-next">LOGIN</span>
              </div>

              <div className="btn-1 join">
                <span className="arr-next">JOIN DAO</span>
              </div>
            </ButtonWrap>
          )}
        </div>

        {this._renderMenuPanel()}

        <ModalLogin open={isOpenModalMember} closeModal={() => handleCloseModal()} />
      </HeaderContainer>
    );
  }
}

function withMyHook(Component) {

  const WrappedComponent = ({
    authStore: auth,
    initialData,
    loggedIn,
    ...props }) => {
    const { web3Auth, provider } = useWeb3Auth();
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenModalMember, setIsOpenModalMember] = useState(false);

    const handleOpenModal = () => setIsOpenModalMember(true);

    const handleCloseModal = () => setIsOpenModalMember(false);
    const userId = initialData.userId || null;
    const history = useHistory();
    useEffect(() => {
      if (userId) {
        if (
          (!initialData.memberFlg && loggedIn && !initialData.member)
          || (loggedIn && initialData?.transactionFailedFlg )
        ) {
          handleOpenModal();
        } else if (initialData.memberFlg && loggedIn) {
          history.push('/top');
        }
      } else {
        handleCloseModal()
      }
    }, [userId, initialData, loggedIn]);

    const [privateKey, setPrivateKey] = useState('')

    const exportPrivateKey = async () => {
      if (provider) {
        const pvk = await provider.getPrivateKey()
        setPrivateKey(pvk)
      } else {
        setPrivateKey('')
      }
      if (privateKey) {
        navigator.clipboard.writeText(privateKey);
        Toast.show('Coppy success')
      }
    }

    return (
      <Spin spinning={isLoading}>
        <Component
          {...props}
          web3Auth={web3Auth}
          isOpenModalMember={isOpenModalMember}
          handleCloseModal={handleCloseModal}
          loggedIn={loggedIn}
        />
      </Spin>
    );
  };

  return inject(stores => ({
    authStore: stores.auth
  }))(
    observer(({ authStore, ...props }) => {
      const { initialData, loggedIn } = authStore
      return (
        <WrappedComponent
          {...props}
          authStore={authStore}
          initialData={{ ...initialData }}
          loggedIn={loggedIn}
        />
      );
    }),
  );
}

export default withMyHook(Header);
