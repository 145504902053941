import { MainApi } from './endpoint';

export function getListSuggestions(payload) {
  return MainApi.get('/user/suggestions', payload);
}

export function createSuggestion(payload) {
  return MainApi.post('/user/suggestions', payload);
}

export function getSuggestionById({ id, ...payload }) {
  return MainApi.get(`/user/suggestions/${id}`, payload);
}

export function updateSuggestion({ id, ...payload }) {
  return MainApi.put(`/user/suggestions/${id}`, payload);
}

export function deleteSuggestion({ id, ...payload }) {
  return MainApi.delete(`/user/suggestions/${id}`, payload);
}

export function requestVotingSuggestion({ id, ...payload }) {
  return MainApi.post(`/user/suggestions/${id}/request-voting`, payload);
}

export function unVotingSuggestion({ id, ...payload }) {
  return MainApi.post(`/user/suggestions/${id}/un-voting`, payload);
}

export function votingSuggestion({ id, ...payload }) {
  return MainApi.post(`/user/suggestions/${id}/voting`, payload);
}

export function updateVotingSuggestion({ id, ...payload }) {
  return MainApi.put(`/user/suggestions/${id}/voting`, payload);
}

export function getCommentsBySuggestionId({ id, ...payload }) {
  return MainApi.get(`/user/suggestions/${id}/comment`, payload);
}

export function commentInSuggestion({ id, ...payload }) {
  return MainApi.post(`/user/suggestions/${id}/comment`, payload)
}

export function updateCommentInSuggestion({ id, commentId, ...payload }) {
  return MainApi.put(`/user/suggestions/${id}/comment/${commentId}`, payload)
}

export function deleteCommentInSuggestion({ id, commentId }) {
  return MainApi.delete(`/user/suggestions/${id}/comment/${commentId}`)
}

export function getListLowComments({ id, parentCommentId, ...payload }) {
  return MainApi.get(`/user/suggestions/${id}/sub-comment/${parentCommentId}`, payload);
}

export function commentInCommentSuggestion({ id, parentCommentId, ...payload }) {
  return MainApi.post(`/user/suggestions/${id}/sub-comment/${parentCommentId}`, payload)
}

export function viewVotingSuggestion({ id, ...payload }) {
  return MainApi.get(`/user/suggestions/${id}/voting`, payload);
}

export function getSuggestionsStatistic(payload) {
  return MainApi.get(`/user/suggestions/statistic`, payload);
}

export function updateFavoriteForComment({ suggestionId, commentId }) {
  return MainApi.put(`/user/suggestions/${suggestionId}/comment-favorite/${commentId}`);
}

export function getOpinionSuggestionByOwnerRequest({ suggestionId }) {
  return MainApi.get(`/user/suggestions/${suggestionId}/opinion`);
}

export function getBlogById({ id }) {
  return MainApi.get(`/user/suggestions/${id}/blog`)
}

export function getListSuggestionBlog({ id }) {
  return MainApi.get(`/user/suggestions/${id}/blog`)
}

export function createSuggestionBlog(payload) {
  return MainApi.post(`/user/suggestions/${payload.id}/blog`, payload)
}

export function requestSuggestionBlogComplete(payload) {
  return MainApi.put(`/user/suggestions/${payload.id}/blog-completed`, payload)
}

export function updateSuggestionBlog(payload) {
  return MainApi.put(`/user/suggestions/${payload.id}/blog/${payload.blogId}`, payload)
}

export function deleteSuggestionBlog(payload) {
  return MainApi.delete(`/user/suggestions/${payload.id}/blog/${payload.blogId}`)
}
