import { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classnames from 'classnames'
import { Input as AntInput } from 'antd'
import lodash from 'lodash'

const StyledInput = styled(AntInput)`
  /* border: 1px solid rgba(255, 255, 255, 0.4); */
  border: 1px solid #d9d9d9;
  height: 40px;
  padding: 0 15px;
  box-shadow: none !important;
  font-size: 16px;
  border-radius: 4px;

  //::placeholder {
  //  color: #b0b4b5;
  //  opacity: 1;
  //}
  //
  //:-ms-input-placeholder {
  //  color: #b0b4b5;
  //}
  //
  //::-ms-input-placeholder {
  //  color: #b0b4b5;
  //}

  .ant-input-prefix {
    margin-right: 10px;
  }

  .ant-input {
    background-color: transparent;
    font-size: 15px;
    color: #333333;
  }

  &.error {
    border: solid 1px #DA1D31;
  }

  // Disable autofill background
  //&:-webkit-autofill,
  //&:-webkit-autofill:hover, 
  //&:-webkit-autofill:focus, 
  //&:-webkit-autofill:active  {
  //    -webkit-box-shadow: 0 0 0 30px white inset !important;
  //}

  .ant-input-focused, 
  &:focus,
  &:hover {
    border: 1px solid #d9d9d9;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

class Input extends Component {
  static propTypes = {
    field: PropTypes.object,
    form: PropTypes.object,
    onChange: PropTypes.func,
    value: PropTypes.any,
    simple: PropTypes.bool
  }

  _onChange = (e) => {
    const { field, form, onChange } = this.props

    if (onChange) {
      onChange(e)
    } else if (field && form) form.setFieldValue(field.name, e.target.value)
  }

  render() {
    const { field, form, value, className, simple, ...props } = this.props

    return (
      <StyledInput
        {...field}
        {...props}
        {...(field && { id: `formik-field-${field.name}` })}
        value={field?.value || value}
        className={classnames({ error: lodash.get(form, `errors.${field?.name}`), simple }, className, 'input')}
        onChange={this._onChange}
      />
    )
  }
}

export default Input
