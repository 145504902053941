import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import lodash from 'lodash';

import { events } from '../services';
import validationMessages from './validation-messages.json';
import errorMessages from './error-messages.json';
import common from './common.json';
import auth from './auth.json';
import productDetails from './product-details.json';
import products from './products.json';
import userProfile from './user-profile.json';
import settings from './settings.json';
import home from './home.json';
import idols from './idols.json';
import eventsJson from './events.json';
import cultureJson from './cultures.json';
import stakingJson from './staking.json';
import notification from './notification.json';
import projects from './project.json';
import suggestions from './suggestions.json';
import stores from './store.json';

const INIT_LANGUAGE = 'ja';

const resources = lodash.merge(
  validationMessages,
  errorMessages,
  common,
  auth,
  productDetails,
  products,
  userProfile,
  settings,
  home,
  idols,
  eventsJson,
  cultureJson,
  stakingJson,
  notification,
  projects,
  suggestions,
  stores
);

i18n.use(initReactI18next).init({
  resources,
  lng: INIT_LANGUAGE,
  fallbackLng: INIT_LANGUAGE,
  interpolation: {
    escapeValue: false,
  },
});

i18n.off('languageChanged');
i18n.on('languageChanged', language => {
  events.languageChange.emit(language);
});

export default i18n;
