import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { Translation } from 'react-i18next';

import Modal from '../components/modal';
import Typography from '../components/typography';
import LoginWeb3AuthComponent from '../components/login-web3auth-component';

const CustomModal = styled(Modal)`
  .ant-modal-body {
    background-color: #fff;
  }
`

const StyledDiv = styled.div`
  padding: 17px 10px 0;

  .title {
    font-size: 24px;
    margin-bottom: 24px;
    font-weight: bold;
    color: #000;
  }

  .title_2 {
    font-size: 18px;
    color: #000;
    font-weight: bold;
  }

  .title_3 {
    font-size: 14px;
    color: #DA1D31;
    font-weight: bold;
  }

  .subtitle {
    margin-bottom: 37px;
  }

  .title-2 {
    margin-bottom: 17px;
  }

  .login-button {
    height: 64px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    padding: 0 25px;
    color: white;
    background: rgb(18, 22, 28);
    background: linear-gradient(90deg, rgba(18, 22, 28, 1) 0%, rgba(56, 38, 112, 1) 100%);
    align-items: center;

    img {
      width: 34px;
    }

    &.metamask {
      background: rgb(245, 132, 31);
      background: linear-gradient(90deg, rgba(245, 132, 31, 1) 0%, rgba(255, 244, 113, 1) 100%);
      margin-bottom: 42px;

      p {
        color: initial;
      }
    }

    &.walletconnect {
      background: rgb(245, 132, 31);
      background: linear-gradient(90deg, rgb(106, 163, 208) 0%, rgb(208, 227, 239) 100%);
      margin-bottom: 42px;
    }
  }

  .faq-link {
    margin-top: 7px;
  }
`;

export const WALLET_LIST = [
  {
    name: 'Metamask',
    link: 'https://metamask.app.link',
  },
  {
    name: 'Trust',
    link: 'https://link.trustwallet.com/open_url?coin_id=60&url=',
  },
];

let instance;

@inject(stores => ({
  authStore: stores.auth,
}))
@observer
class LoginModal extends Component {
  static propTypes = {
    authStore: PropTypes.object,
  };

  state = {
    isOpen: false,
    loading: null,
  };

  static setInstance = ref => {
    instance = ref;
  };

  static open = () => {
    if (instance) {
      return instance.open();
    }

    return null;
  };

  open = () => {
    this.setState({
      isOpen: true,
    });
  };

  close = () => this.setState({ isOpen: false });

  _generateDappUrl = ({ link, name }) => {
    if (link) {
      if (name === 'Metamask') {
        const dappUrl = `${window.location.href}`.trim();
        if (dappUrl.search('https://') !== -1) {
          const pageUrl = `${link}/dapp/${dappUrl.replace('https://', '')}`;
          window.location.href = pageUrl;
        }
      }
    }
  };

  render() {
    const { isOpen } = this.state;

    return (
      <CustomModal open={isOpen} onCancel={this.close} destroyOnClose maskClosable={false}>
        <Translation ns="auth">
          {t => (
            <StyledDiv>
              <Typography className="title" bold center>
                {t('楽座DAOへようこそ！')}
              </Typography>

              <Typography className="title_2" center>
                {t('次の手順に従って初回登録をお願いします。')}
              </Typography>
              <br />

              <Typography className="title_2" bold center>
                {t('Web3.0のウォレットの準備をお願いします。')}
              </Typography>
              <Typography className="title_2" bold center>
                {t('楽座DAOでは2種類のウォレットをサポートしています。')}
              </Typography>
              <br />
              <br />

              <Typography className="title_3" bold center>
                {t('※既に引換NFTをお持ちの方は引換NFTがウォレット内にある')}
              </Typography>
              <Typography className="title_3" bold center>
                {t('Metamaskを選択ください。')}
              </Typography>
              <br />
              <br />

              <LoginWeb3AuthComponent onClose={this.close} isOpen={isOpen} />
            </StyledDiv>
          )}
        </Translation>
      </CustomModal>
    );
  }
}

export default LoginModal;
