import { Button as AntButton } from 'antd';
import styled from 'styled-components';
import classnames from 'classnames';
import React from 'react';

import { Colors } from '../theme';
import Media from '../utils/media';

const StyledButton = styled(AntButton)`
  height: ${props => `${props?.height ? props?.height : 55}px`};
  width: ${props => `${props.width}px` || 'auto'};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border-radius: 4px;
  border: none;
  background-color: ${props => props.background || Colors.PRIMARY};
  color: #ffffff;
  font-size: ${props => `${props?.fontSize ? props?.fontSize : 15}px`};
  padding: 0 28px;

  &.ant-btn > .anticon + span,
  &.ant-btn > span + .anticon {
    margin-left: 0;
  }

  &:hover,
  &:active,
  &:focus  {
    color: #ffffff;
    background-color: ${props => props.background || Colors.PRIMARY};
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:focus {
    color: #cccccc !important;
    background-color: #7a7a7a !important;
    span {
      color: #cccccc !important;
    }
  }

  &.br-100 {
    border-radius: 100px;
  }

  &.large {
    height: 57px;
    font-size: 16px;
  }

  &.white {
    color: #DA1D31;
    border: 1px solid #DA1D31;
    border-radius: 4px;
    background: #fff;
  }

  &.form-gray {
    height: 38px;
    color: #777777;
    padding: 0 30px;
    background-color: #F1F1F1;
    border:1px solid #DDDDDD;
    border-radius: 9999px;
  }

  &.close {
    height: 42px;
    border-radius: 3px;
    padding: 0 30px;
    border: 1px solid #da1d31;
    background-color: #fff;
    color: #da1d31;
    font-size: 14px;
    font-weight: bold;
  }

  &.black {
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: #000000;
    font-size: 15px;
  }

  img.icon_button {
    height: 14px;
    width: auto;
  }

  &.h35 {
    height: 35px;
  }

  &.b-gray {
    border: 1px solid #DDDDDD;
    color: black;
  }

  &.after::after {
    content: ' ';
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    width: 4px;
    border-width: 4px;
    border-style: solid;
    border-color: transparent transparent transparent #fff;
  }

  ${Media.lessThan(Media.SIZE.MD)} {
    height: 38px;
    padding: 0 26px;
    font-size: 12px;

    &.large {
      height: 47px;
      font-size: 14px;
    }
  }
`;

const Button = ({ children, size, className, icon, ...props }) => (
  <StyledButton {...props} className={classnames(size, className, 'bold')}>
    {!!icon && <img src={icon} alt="icon_button" className='icon_button' />}
    {children}
  </StyledButton>
);
export default Button;
