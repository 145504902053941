import React, { useMemo } from 'react';
import { ModalStyled, ModalStyledChild, StepItemStyled, TitleStyled, TopTextStyled, WrapStepStyled } from './styled';
import Step2 from './step2';
import Step3 from './step3';
import Step1 from './step1';
import { Form } from 'antd';
import { useFormik } from 'formik';
import { inject, observer } from 'mobx-react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import Clickable from '@/components/clickable';

function ModalLogin({ open, closeModal, auth, isMyPage, buyingProduct }) {
  const { t } = useTranslation();

  const [isOpenModalBalance, setIsModalBalance] = React.useState()

  React.useEffect(() => {
    (async () => {
      if (open) {
        const { success, data } = await auth.getBalanceNftByTokenId();
        await auth.fetchPrefecture({});
        if (success) {
          const { balance } = data
          const { SWAP_RAKUZA_DAO, SWAP_RAKUZA_OTHER } = balance
          if (!SWAP_RAKUZA_DAO && !SWAP_RAKUZA_OTHER) {
            setIsModalBalance(true)
          }
        }
      }
    })()
  }, [open])


  const userProducts = auth?.initialData?.userProducts || [];
  const valueStep1 = {
    isChecked: false,
  };

  const valueStep2 = {
    nickname: '',
    email: '',
    phoneNumber: '',
    age: '',
    prefectureId: null,
  };

  const handleSubmitRegisterMember = async values => {
    const payload = {
      age: values?.age ?? 0,
      email: values?.email ?? '',
      nickname: values?.nickname ?? '',
      phoneNumber: values?.phoneNumber ?? '',
      prefectureId: values?.prefectureId ?? 0,
    };

    try {
      await auth.registerMember(payload);

      closeModal();
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  };

  const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: !!auth.initialData.transactionFailedFlg ? {
      step: 4,
      ...valueStep1,
      ...valueStep2,
      nickname: auth?.initialData?.member?.nickname || '',
      email: auth?.initialData?.member?.email || '',
      phoneNumber: auth?.initialData?.member?.phoneNumber || '',
      age: auth?.initialData?.member?.age || '',
      prefectureId: auth?.initialData?.member?.prefectureId || '',
    } : {
      step: isMyPage ? 4 : 1,
      ...valueStep1,
      ...valueStep2,
    },
    validateOnChange: true,
    validationSchema: yup.object().shape({
      nickname: yup
        .string()
        .trim()
        .max(255, t('validation_messages:NICKNAME_MAX_256'))
        .required(t('validation_messages:NICKNAME_REQUIRED')),
      email: yup
        .string()
        .trim()
        .email(t('validation_messages:EMAIL_INVALID'))
        .max(50, t('validation_messages:MAX_LENGTH_50'))
        .required(t('validation_messages:EMAIL_REQUIRED')),
      phoneNumber: yup
        .string().trim()
        .min(10, t('validation_messages:PHONE_FORMAT'))
        .max(11, t('validation_messages:PHONE_FORMAT'))
        .matches(phoneRegExp, t('validation_messages:PHONE_FORMAT'))
        .required(t('validation_messages:PHONE_REQUIRED')),
      age: yup
        .number()
        .min(1, t('validation_messages:VALIDATE_AGE'))
        .max(150, t('validation_messages:VALIDATE_AGE'))
        .required(t('validation_messages:VALIDATE_AGE')),
      prefectureId: yup.number().required(t('validation_messages:PREFECTURE_REQUIRED')),
    }),

    onSubmit: values => handleSubmitRegisterMember(values),
  });

  const { values } = formik;

  const renderStep = useMemo(() => {
    if (values.step === 2 || values.step === 3) {
      return <Step2 formik={formik} handleSubmit={formik.handleSubmit} auth={auth} />;
    }

    if (values.step === 4) {
      return <Step3 formik={formik} handleSubmit={formik.handleSubmit} closeModal={closeModal} isMyPage={isMyPage} />;
    }

    return <Step1 formik={formik} />;
  }, [values.step, formik, closeModal, isMyPage, auth]);

  return (
    <ModalStyled
      width={714}
      title={<TitleStyled>楽座DAOへようこそ！</TitleStyled>}
      open={open}
      onOk={() => { }}
      onCancel={() => {
        formik.resetForm({});
        closeModal();
      }}
      footer={null}
      maskClosable={false}
      destroyOnClose
      centered
    >
      {((!auth.initialData.memberFlg
        && !auth.initialData.transactionFailedFlg
        && auth.loggedIn
        && auth.initialData.member?.status === 'DRAFT'
      ) || !!buyingProduct) ? (
        <TopTextStyled>トランザクションは処理しています。少々お待ちください。</TopTextStyled>
      ) : (
        <>
          {!isMyPage ? (
            <>
              <TopTextStyled>次の手順に従って初回登録をお願いします。</TopTextStyled>
              <WrapStepStyled>
                <StepItemStyled className={`${values.step === 1 ? 'active' : ''}`}>
                  利用規約の
                  <br />
                  確認・同意
                </StepItemStyled>
                <StepItemStyled className={`${values.step === 2 ? 'active' : ''}`}>
                  メンバー
                  <br />
                  情報登録
                </StepItemStyled>
                <StepItemStyled className={`${values.step === 3 ? 'active' : ''}`}>
                  登録情報の
                  <br />
                  確認
                </StepItemStyled>
                {!!userProducts?.length ? (
                  ''
                ) : (
                  <StepItemStyled className={`${values.step === 4 ? 'active' : ''}`}>
                    メンバーシップ
                    <br />
                    NFT購入
                  </StepItemStyled>
                )}
              </WrapStepStyled>
            </>
          ) : (
            <></>
          )}
          <Form
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            onFinish={() => { }}
            onFinishFailed={() => { }}
            autoComplete="off"
          >
            {renderStep}
          </Form>
        </>
      )}

      {
        !!buyingProduct
          ? ''
          : <ModalStyledChild
            open={isOpenModalBalance}
            destroyOnClose
            centered
            footer={false}
            width={640}
          >
            <div className='description_1'>
              ウォレットから楽座DAOの会員証NFTの引換券を検出できませんでした。
            </div>
            <div className='description_2'>
              現在、楽座DAOに登録するには、会員証NFTの引換NFTが必要になりますので、下記よりご購入をお願い致します。
            </div>
            <div className='block_btn'>
              <Clickable className="btn_cancel"
                onClick={() => {
                  setIsModalBalance(!isOpenModalBalance)
                }}
              >キャンセル</Clickable>
              <Clickable className="btn_go" onClick={() => {
                setIsModalBalance(!isOpenModalBalance)
                if (process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'staging') {
                  window.open(`${process.env.REACT_APP_RAKUZA_NEW}/ja/category/rakuzadao_exchange/800`, '_blank')
                } else {
                  window.open(`${process.env.REACT_APP_RAKUZA_NEW}/ja/category/rakuzadao_exchange/1857`, '_blank')
                }

              }}>購入</Clickable>
            </div>
          </ModalStyledChild>
      }
    </ModalStyled>
  );
}

export default inject(stores => ({
  authStore: stores.auth,
  initialData: stores.auth.initialData
}))(
  observer(({ ...props }) => {
    const { authStore: auth, initialData } = props;

    return <ModalLogin {...props} auth={auth} buyingProduct={initialData.buyingProduct} />;
  }),
);
