import PropTypes from 'prop-types'
import styled from 'styled-components'
import classnames from 'classnames'

const StyledDiv = styled.div`
  cursor: pointer;
  user-select: none;
  transition: opacity 0.2s;
  font-size: 14px;

  &.disabled {
    background-color: #7f7f7f;
    cursor: not-allowed;
  }
 
  &.effect {
    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.7;
    }
  }
`

const Clickable = ({ children, className, onClick, effect, disabled, ...props }) => (
  <StyledDiv
    {...props}
    className={classnames({ effect }, className, disabled && 'disabled')}
    onClick={() => {
      if (disabled) return
      if ( !onClick ) return 
      onClick()
    }}
  >
    {children}
  </StyledDiv>
)
Clickable.propTypes = {
  onClick: PropTypes.func,
  effect: PropTypes.bool
}
Clickable.defaultProps = {
  effect: true
}

export default Clickable
