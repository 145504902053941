/* eslint-disable no-console */
import Web3 from 'web3';
import { getPublicCompressed } from '@toruslabs/eccrypto';
import { Web3AuthNoModal } from '@web3auth/no-modal';
import { OpenloginAdapter } from '@web3auth/openlogin-adapter';
import { MetamaskAdapter } from '@web3auth/metamask-adapter';
import { EthereumPrivateKeyProvider } from '@web3auth/ethereum-provider';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { ADAPTER_EVENTS, CHAIN_NAMESPACES, getEvmChainConfig } from '@web3auth/base';

import { getWalletProvider } from './walletProvider';
import Toast from '../../components/toast';
import auth from '../../store/auth';
import { getNetworkSelected } from '../../utils/auth';
import Storage from '../../utils/storage';
import Misc from '../../utils/misc';
import Confirmable from '../../components/confirmable';
import { CHAIN_LIST } from '../../constants/chains';
import { t } from 'i18next';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

export const Web3AuthContext = createContext({
  web3Auth: null,
  provider: null,
  isLoading: false,
  user: null,
  login: async (adapter, provider, login_hint) => {},
  logout: async () => {},
  getUserInfo: async () => {},
  getAccounts: async () => {},
  getBalance: async () => {},
  getAppPubKey: async () => {},
  getPrivateKey: async () => {},
  signMessage: async () => {},
  ethereum: null,
});

export function useWeb3Auth() {
  return useContext(Web3AuthContext);
}

const env = process.env.REACT_APP_ENV;
const networkWeb3auth = process.env.REACT_APP_WEB3_AUTH_NETWORKS ?? 'sapphire_mainnet';
const network = getNetworkSelected(env, Storage.get('chain'));
console.log("network", network);
const currentChainConfig = {
  displayName: network?.displayName,
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  chainId: `0x${Number(network?.chainId).toString(16)}`,
  rpcTarget: network?.rpcUrls[0],
  blockExplorer: network?.blockExplorerUrls[0],
  ticker: network?.symbol,
  tickerName: "Ethereum",
  decimals: network?.decimals,
};


const clientId = process.env.REACT_APP_WEB3_AUTH_CLIENT_ID;
const sessionTime = process.env.REACT_APP_SESSION_TIME;

export const Web3AuthProvider = ({ children }) => {
  const { search } = useLocation();
  const history = useHistory();
  const [web3Auth, setWeb3Auth] = useState(null);
  const [provider, setProvider] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const setWalletProvider = useCallback(web3authProvider => {
    const walletProvider = getWalletProvider(network?.chainId, web3authProvider);
    setProvider(walletProvider);
  }, []);

  const handleLoginCrossSite = async () => {
    if (search?.includes('authToken')) {
      history.replace('/');
      window.location.reload();
    }
  };

  useEffect(() => {
    const subscribeAuthEvents = web3authInstance => {
      web3authInstance.on(ADAPTER_EVENTS.CONNECTED, data => {
        console.log('ADAPTER_EVENTS --------------- CONNECTED');
        setUser(data);
        setWalletProvider(web3authInstance.provider);
      });

      web3authInstance.on(ADAPTER_EVENTS.CONNECTING, () => {
        console.log('ADAPTER_EVENTS --------------- CONNECTING');
      });
      web3authInstance.on(ADAPTER_EVENTS.ADAPTER_DATA_UPDATED, () => {
        console.log('ADAPTER_EVENTS --------------- ADAPTER_DATA_UPDATED');
      });
      web3authInstance.on(ADAPTER_EVENTS.CACHE_CLEAR, () => {
        console.log('ADAPTER_EVENTS --------------- CACHE_CLEAR');
      });
      web3authInstance.on(ADAPTER_EVENTS.NOT_READY, () => {
        console.log('ADAPTER_EVENTS --------------- NOT_READY');
      });
      web3authInstance.on(ADAPTER_EVENTS.READY, () => {
        console.log('ADAPTER_EVENTS --------------- READY');
      });
      
      web3authInstance.on(ADAPTER_EVENTS.DISCONNECTED, async () => {
        console.log('ADAPTER_EVENTS --------------- DISCONNECTED');
        // if (web3authInstance.connected) {
        // await web3authInstance.logout();
        // }
        auth.logout();
        setUser(null);
      });

      web3authInstance.on(ADAPTER_EVENTS.ERRORED, async error => {
        console.log('ADAPTER_EVENTS --------------- ERRORED', error);
        // web3authInstance.logout();
        // setProvider(null);
        // setUser(null);
      });
    };

    async function init() {
      try {
        if (localStorage.getItem('Web3Auth-cachedAdapter')) setIsLoading(true);
        
        const web3AuthInstance = new Web3AuthNoModal({
          chainConfig: currentChainConfig,
          enableLogging: true,
          clientId: clientId,
          sessionTime: Number(sessionTime), // default: 86400
          web3AuthNetwork: networkWeb3auth,
        });

        subscribeAuthEvents(web3AuthInstance);
        
        const openloginAdapter = new OpenloginAdapter({
          privateKeyProvider: new EthereumPrivateKeyProvider({
            config: {
              // chainConfig: getEvmChainConfig(1)
              chainConfig: process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'staging' ? currentChainConfig : getEvmChainConfig(1),
            },
          }),
          loginSettings: {
            mfaLevel: 'none',
          },
          adapterSettings: {
            network: networkWeb3auth,
            clientId
          },
        });
        
        const metamaskAdapter = new MetamaskAdapter({
          clientId,
          chainConfig: currentChainConfig,
        });
        
        web3AuthInstance.configureAdapter(openloginAdapter);
        web3AuthInstance.configureAdapter(metamaskAdapter);

        await web3AuthInstance.init();
        
        await handleLoginCrossSite();

        setWeb3Auth(web3AuthInstance);
        
        if ( web3AuthInstance?.provider) {
          setWalletProvider(web3AuthInstance.provider)
        }

        if (!web3AuthInstance?.provider) setIsLoading(false);
      } catch (error) {
        Toast.error(error?.message || 'Initial web3 auth fail.');
      } finally {
        setIsLoading(false);
      }
    }
    init();
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setWalletProvider]);

  const switchChain = async chainId => {
    try {
      const advancedNetwork = getNetworkSelected(env, chainId);
      console.log("advancedNetwork", advancedNetwork);
      const advancedChainConfig = {
        displayName: advancedNetwork?.displayName,
        chainNamespace: CHAIN_NAMESPACES.EIP155,
        chainId: `0x${Number(advancedNetwork?.chainId).toString(16)}`,
        rpcTarget: advancedNetwork?.rpcUrls[0],
        blockExplorer: advancedNetwork?.blockExplorerUrls[0],
        ticker: advancedNetwork?.symbol,
        tickerName: advancedNetwork?.currency,
        decimals: advancedNetwork?.decimals,
      };

      await web3Auth
        .addChain(advancedChainConfig)
        .then(async () => {
          await web3Auth.switchChain({ chainId: `0x${chainId.toString(16)}` });
          await auth.setCurrentChain(chainId);
        })
        .catch(async e => {
          throw e;
        });
    } catch (error) {
      throw error;
    }
  };
  
  const login = async (adapter, loginProvider, login_hint) => {
    
    try {
      if (!web3Auth) {
        return;
      }

      const localProvider = await web3Auth.connectTo(adapter, { loginProvider, login_hint });
      if ( localProvider ) {
        setWalletProvider(localProvider);
        const walletProvider = getWalletProvider(network?.chainId, localProvider);
        
        return await walletProvider.getAccounts();
      } else {
        throw new Error('User close modal loggin')
      }
    } catch (error) {
      if (error?.code === 5111) {
        Confirmable.open({
          content: (
            <>
              {t('common:for_access_from_pc')}
              <br />
              <br />
              {t('common:for_access_from_mobile')}
              <br />
              <br />
              {t('common:otherwise_error_login')}
            </>
          ),
          hideCancelButton: true,
        });
      }

      if (error.error === undefined && error.mesage === undefined) {
        if (Misc.isMobile && !Misc.checkNotMetamaskBrowser()) {
          Confirmable.open({
            content: (
              <>
                {t('validation_messages:SOMETHING_WENT_WRONG')}
                <br />
                {t('common:metamask_switch_network_to', { network: CHAIN_LIST[network?.chainId].displayName })}
              </>
            ),
            hideCancelButton: true,
          });
        } else {
          await Confirmable.open({
            content: t('common:please_try_again'),
            hideCancelButton: true
          });

          Storage.clear();
          return window.location.reload();
        }
      }
      throw error;
    }
  };
  

  const logout = async () => {
    if (!web3Auth) {
      return;
    }
    try {
      if (web3Auth.connected) {
        await web3Auth.logout();
      }
    } catch (e) {}

    setProvider(null);
  };

  const getUserInfo = async () => {
    try {
      if (!web3Auth) {
        return;
      }

      const user = await web3Auth?.getUserInfo();
      return user;
    } catch (error) {
      return error?.message;
    }
  };

  const getAccounts = useCallback(async () => {
    if (!provider) {
      return null;
    }
    const accounts = await provider.getAccounts();

    return accounts;
  }, [provider]);

  const getChainId = async () => {
    if (!provider) {
      return null;
    }
    const chainId = await provider.getCurrentChainId();

    return chainId;
  };

  const getBalance = async () => {
    if (!provider) {
      return;
    }

    const balance = await provider.getBalance();

    return balance;
  };

  const getAppPubKey = async () => {
    try {
      const app_scoped_privkey = await web3Auth.provider?.request({
        method: 'eth_private_key', // use "private_key" for other non-evm chains
      });

      const app_pub_key = getPublicCompressed(Buffer.from(app_scoped_privkey.padStart(64, '0'), 'hex')).toString('hex');

      return app_pub_key;
    } catch (error) {
      return error;
    }
  };

  const getPrivateKey = async () => {
    if (!provider) {
      return null;
    }

    try {
      // const walletPrivateKeyWeb3Auth = await provider?.getWalletPrivateKey();
      const privateKeyWeb3Auth = await provider?.getPrivateKey();

      return privateKeyWeb3Auth;
    } catch (error) {
      return null;
    }
  };

  const signMessage = async message => {
    try {
      const web3 = new Web3(provider);

      // Get user's Ethereum public address
      const fromAddress = (await provider.getAccounts())[0];

      // const sign = await provider.signMessage();

      // Sign the message
      const signedMessage = await web3.eth.personal.sign(
        message,
        fromAddress,
        '', // configure your own password here.
      );

      return signedMessage;
    } catch (error) {
      return error;
    }
  };

  const contextProvider = {
    web3Auth,
    provider,
    user,
    isLoading,
    setIsLoading,
    login,
    logout,
    getUserInfo,
    getChainId,
    getAccounts,
    getBalance,
    getAppPubKey,
    getPrivateKey,
    signMessage,
    switchChain,
  };
  return <Web3AuthContext.Provider value={contextProvider}>{children}</Web3AuthContext.Provider>;
};
