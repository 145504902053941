import { Model } from "../utils/mobx-model-helper"
import {
  purchareProduct,
  checkAllowPurchase,
  confirmPurchaseProduct,
  cancelPurchaseProduct,
  cancelPurchaseProductNormal,
  purchareProduct1155,
  purchareProduct1155ByETH,
  creditCardPayment,
  paymentWithSlash,
  paymentPoint,
} from "../api/payment"

const TYPES = {
  PURCHARE_PRODUCT: 1,
  CHECK_ALLOW_PURCHASE: 2,
  CONFIRM_PURCHASE_PRODUCT: 3,
  CANCEL_PURCHASE_PRODUCT: 4,
  CANCEL_PURCHASE_PRODUCT_NORMAL: 5,
  PURCHARE_PRODUCT_1155: 6,
  CREDIT_CARD_PAYMENT: 7,
  PAYMENT_WITH_SLASH: 8,
  PAYMENT_POINT: 9,
  PURCHARE_PRODUCT_1155_BY_ETH: 10
}

const PaymentStore = Model.named("PaymentStore").actions((self) => ({
  purchareProduct(payload) {
    return self.request({
      type: TYPES.PURCHARE_PRODUCT,
      api: purchareProduct,
      payload,
      disabledErrorMessage: true,
    })
  },

  checkAllowPurchase(payload) {
    return self.request({
      type: TYPES.CHECK_ALLOW_PURCHASE,
      api: checkAllowPurchase,
      payload,
      // disabledErrorMessage: true,
    })
  },

  confirmPurchaseProduct(payload) {
    return self.request({
      type: TYPES.CONFIRM_PURCHASE_PRODUCT,
      api: confirmPurchaseProduct,
      payload,
    })
  },

  cancelPurchaseProduct(payload) {
    return self.request({
      type: TYPES.CANCEL_PURCHASE_PRODUCT,
      api: cancelPurchaseProduct,
      payload,
      disabledErrorMessage: true,
    })
  },

  cancelPurchaseProductNormal(payload) {
    return self.request({
      type: TYPES.CANCEL_PURCHASE_PRODUCT_NORMAL,
      api: cancelPurchaseProductNormal,
      payload,
    })
  },

  purchareProduct1155(payload) {
    return self.request({
      type: TYPES.PURCHARE_PRODUCT_1155,
      api: purchareProduct1155,
      payload,
      disabledErrorMessage: true,
    })
  },

  purchareProduct1155ByETH(payload) {
    return self.request({
      type: TYPES.PURCHARE_PRODUCT_1155_BY_ETH,
      api: purchareProduct1155ByETH,
      payload,
      disabledErrorMessage: true,
    })
  },
  
  creditCardPayment(payload) {
    return self.request({
      type: TYPES.CREDIT_CARD_PAYMENT,
      api: creditCardPayment,
      disabledErrorMessage: true,
      payload,
    })
  },
  paymentWithSlash(payload) {
    return self.request({
      type: TYPES.PAYMENT_WITH_SLASH,
      api: paymentWithSlash,
      disabledErrorMessage: true,
      payload,
    })
  },
  paymentPoint(payload) {
    return self.request({
      type: TYPES.PAYMENT_POINT,
      api: paymentPoint,
      payload,
    })
  },
}))

export { TYPES }
export default PaymentStore.create()

