import { types } from 'mobx-state-tree';

import { Model } from '../utils/mobx-model-helper';

import {
  getListSuggestions,
  createSuggestion,
  getSuggestionById,
  updateSuggestion,
  deleteSuggestion,
  requestVotingSuggestion,
  unVotingSuggestion,
  votingSuggestion,
  getCommentsBySuggestionId,
  commentInSuggestion,
  updateCommentInSuggestion,
  deleteCommentInSuggestion,
  getListLowComments,
  commentInCommentSuggestion,
  viewVotingSuggestion,
  getSuggestionsStatistic,
  updateFavoriteForComment,
  updateVotingSuggestion,
  getOpinionSuggestionByOwnerRequest,
  getBlogById,
  createSuggestionBlog,
  requestSuggestionBlogComplete,
  updateSuggestionBlog,
  deleteSuggestionBlog,
  getListSuggestionBlog,
} from '../api/suggestions';

const TYPES = {
  GET_LIST_SUGGESTIONS: 1,
  CREATE_SUGGESTION: 2,
  GET_SUGGESTION_BY_ID: 3,
  UPDATE_SUGGESTION: 4,
  DELETE_SUGGESTION: 5,
  REQUEST_VOTING_SUGGESTION: 5,
  COMMENT_IN_SUGGESTION: 6,
  UPDATE_COMMENT_INSUGGESTION: 7,
  DELETE_COMMENT_IN_SUGGESTION: 8,
  GET_LIST_LOW_COMMENTS: 9,
  COMMENT_IN_COMMENT_SUGGESTION: 10,
  GET_COMMENTS_BY_SUGGESTION_ID: 11,
  UN_VOTING_SUGGESTION: 12,
  VOTING_SUGGESTION: 13,
  VIEW_VOTING_SUGGESTION: 14,
  SUGGESTION_STATISTIC: 15,
  UPDATE_FAVORITE_FOR_COMMENT: 16,
  UPDATE_VOTING_SUGGESTION: 17,
  GET_OPINION_SUGGESTION_BY_OWNER_REQUEST: 18,
  GET_BLOG_BY_ID: 19,
  CREATE_SUGGESTION_BLOG: 20,
  REQUEST_SUGGESTION_BLOG_COMPLETE: 21,
  UPDATE_SUGGESTION_BLOG: 22,
  DELETE_SUGGESTION_BLOG: 23,
  GET_LIST_SUGGESTION_BLOG: 23,
};

const Suggestion = types.model('Suggestion')
  .props({
    id: types.maybeNull(types.identifierNumber),
    // tittle: types.maybeNull(types.string),
    // content: types.maybeNull(types.string),
    // status: types.maybeNull(types.string),
    // blog: types.maybeNull(types.string),
    // userId: types.maybeNull(types.number),
    // userName: types.maybeNull(types.string),
    // votingType: types.maybeNull(types.string),
    // votingStartTime: types.maybeNull(types.number),
    // votingEndTime: types.maybeNull(types.number),
    // totalUserVoted: types.maybeNull(types.number),
    // totalNftVoted: types.maybeNull(types.number),
  })

const Comment = types.model('Comment')
  .props({
    id: types.maybeNull(types.number),
    content: types.maybeNull(types.string),
    sendDate: types.maybeNull(types.number),
    sender: types.maybeNull(types.string)
  })
  .actions(self => ({
    setComment(payload) {
      self.content = payload.content
    }

  }))


const viewVotings = types.model('Suggestion')
  .props({
    userId: types.maybeNull(types.identifierNumber),
    acceptedFlg: types.maybeNull(types.boolean),
    totalVoted: types.maybeNull(types.number),
    userImage: types.maybeNull(types.string),
    userName: types.maybeNull(types.string),
  })

const SuggestionsStore = Model.named('SuggestionsStore')
  .props({
    listSuggestions: types.model({
      page: types.number,
      total: types.number,
      items: types.array(Suggestion)
    }),

    commentsById: types.model({
      page: types.number,
      total: types.number,
      items: types.array(Comment),
      pages: types.number
    }),

    listLowComments: types.model({
      page: types.number,
      total: types.number,
      items: types.array(Comment),
      pages: types.number
    }),

    viewVotings: types.model({
      page: types.number,
      total: types.number,
      items: types.array(viewVotings),
      pages: types.number
    }),
  })
  .actions(self => ({
    getListSuggestions(payload) {
      return self.request({
        type: TYPES.GET_LIST_SUGGESTIONS,
        api: getListSuggestions,
        payload,
        // onSuccess: (result) => {
        //   self.listSuggestions.page = result.page
        //   self.listSuggestions.total = result.total
        //   self.listSuggestions.items = result.result
        // },
      })
    },

    createSuggestion(payload) {
      return self.request({
        type: TYPES.CREATE_SUGGESTION,
        api: createSuggestion,
        payload,
      })
    },

    getSuggestionById(payload) {
      return self.request({
        type: TYPES.GET_SUGGESTION_BY_ID,
        api: getSuggestionById,
        payload,
      })
    },

    updateSuggestion(payload) {
      return self.request({
        type: TYPES.UPDATE_SUGGESTION,
        api: updateSuggestion,
        payload,
      })
    },

    deleteSuggestion(payload) {
      return self.request({
        type: TYPES.DELETE_SUGGESTION,
        api: deleteSuggestion,
        payload,
      })
    },

    requestVotingSuggestion(payload) {
      return self.request({
        type: TYPES.REQUEST_VOTING_SUGGESTION,
        api: requestVotingSuggestion,
        payload,
      })
    },

    unVotingSuggestion(payload) {
      return self.request({
        type: TYPES.UN_VOTING_SUGGESTION,
        api: unVotingSuggestion,
        payload,
      })
    },

    votingSuggestion(payload) {
      return self.request({
        type: TYPES.VOTING_SUGGESTION,
        api: votingSuggestion,
        payload,
      })
    },

    updateVotingSuggestion(payload) {
      return self.request({
        type: TYPES.UPDATE_VOTING_SUGGESTION,
        api: updateVotingSuggestion,
        payload,
      })
    },

    commentInSuggestion(payload) {
      return self.request({
        type: TYPES.COMMENT_IN_SUGGESTION,
        api: commentInSuggestion,
        payload,
        onSuccess: (result) => {
          self.commentsById.items = self.commentsById.items.concat(result)
        }
      })
    },

    updateCommentInSuggestion(payload) {
      return self.request({
        type: TYPES.COMMENT_IN_SUGGESTION,
        api: updateCommentInSuggestion,
        payload,
      })
    },

    deleteCommentInSuggestion(payload) {
      return self.request({
        type: TYPES.DELETE_COMMENT_IN_SUGGESTION,
        api: deleteCommentInSuggestion,
        payload,
        onSuccess: () => {
          const objEdit = self.commentsById.items.filter(item => item.id !== payload.commentId)

          self.commentsById.items = objEdit
        }
      })
    },

    getListLowComments(payload, concat) {
      return self.request({
        type: TYPES.GET_LIST_LOW_COMMENTS,
        api: getListLowComments,
        payload,
        onSuccess: (result) => {
          self.commentsById.page = result.page
          self.commentsById.total = result.total
          self.commentsById.items = concat ? self.commentsById.items.concat(result.result) : result.result
          self.commentsById.pages = result.pages
        }
      })
    },

    commentInCommentSuggestion(payload) {
      return self.request({
        type: TYPES.COMMENT_IN_COMMENT_SUGGESTION,
        api: commentInCommentSuggestion,
        payload,
      })
    },

    getCommentsBySuggestionId(payload, concat) {
      return self.request({
        type: TYPES.GET_COMMENTS_BY_SUGGESTION_ID,
        api: getCommentsBySuggestionId,
        payload,
        onSuccess: (result) => {
          self.commentsById.page = result.page
          self.commentsById.total = result.total
          self.commentsById.items = concat ? self.commentsById.items.concat(result.result) : result.result
          self.commentsById.pages = result.pages
        }
      })
    },
    viewVotingSuggestion(payload) {
      return self.request({
        type: TYPES.VIEW_VOTING_SUGGESTION,
        api: viewVotingSuggestion,
        payload,
        onSuccess: (result) => {
          self.viewVotings.page = result.page
          self.viewVotings.total = result.total
          self.viewVotings.items = result.result
          self.viewVotings.pages = result.pages
        },
        onError: () => {
          self.viewVotings = {
            page: 0,
            total: 0,
            items: [],
            pages: 0
          }
        }
      })
    },
    getSuggestionsStatistic(payload) {
      return self.request({
        type: TYPES.SUGGESTION_STATISTIC,
        api: getSuggestionsStatistic,
        payload,
        onSuccess: (result) => {
          self.statistic = { ...result }
        },
      })
    },

    updateFavoriteForComment(payload) {
      return self.request({
        type: TYPES.UPDATE_FAVORITE_FOR_COMMENT,
        api: updateFavoriteForComment,
        payload
      })
    },

    getOpinionSuggestionByOwnerRequest(payload) {
      return self.request({
        type: TYPES.GET_OPINION_SUGGESTION_BY_OWNER_REQUEST,
        api: getOpinionSuggestionByOwnerRequest,
        payload
      })
    },

    getBlogById(payload) {
      return self.request({
        type: TYPES.GET_BLOG_BY_ID,
        api: getBlogById,
        payload
      })
    },

    getListSuggestionBlog(payload) {
      return self.request({
        type: TYPES.GET_LIST_SUGGESTION_BLOG,
        api: getListSuggestionBlog,
        payload
      })
    },

    createSuggestionBlog(payload) {
      return self.request({
        type: TYPES.CREATE_SUGGESTION_BLOG,
        api: createSuggestionBlog,
        payload
      })
    },

    requestSuggestionBlogComplete(payload) {
      return self.request({
        type: TYPES.REQUEST_SUGGESTION_BLOG_COMPLETE,
        api: requestSuggestionBlogComplete,
        payload
      })
    },

    updateSuggestionBlog(payload) {
      return self.request({
        type: TYPES.UPDATE_SUGGESTION_BLOG,
        api: updateSuggestionBlog,
        payload
      })
    },

    deleteSuggestionBlog(payload) {
      return self.request({
        type: TYPES.DELETE_SUGGESTION_BLOG,
        api: deleteSuggestionBlog,
        payload
      })
    },

  }))

export { TYPES };

export default SuggestionsStore.create({
  listSuggestions: {
    page: 0,
    total: 0,
    items: []
  },
  commentsById: {
    page: 0,
    total: 0,
    items: [],
    pages: 0
  },

  listLowComments: {
    page: 0,
    total: 0,
    items: [],
    pages: 0
  },

  viewVotings: {
    page: 0,
    total: 0,
    items: [],
    pages: 0
  },

  statistic: {
    totalDiscussion: 0,
    totalVoting: 0
  },
});
