import React, { Component, Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Request from '../utils/request';
import Loading from '../components/loading';
import Page from '../components/page';
import ScrollToTop from '../utils/scrollToTop';
import HeaderDaoSite from './daosite-header';

const HomeScreen = lazy(() => import('../screen/daosite-home'));
// const ProductDetails = lazy(() => import('../screen/product-details'));
// const CategoryDetails = lazy(() => import('../screen/category-details'));
const TopScreen = lazy(() => import('../screen/top'));
const NewsScreen = lazy(() => import('../screen/news'));
const NewsDetailScreen = lazy(() => import('../screen/news-detail'));
const MyPageScreen = lazy(() => import('../screen/my-page'));
const SettingScreen = lazy(() => import('../screen/setting'));
const PolicyScreen = lazy(() => import('../screen/footer-page/policy'));
const TermsOfUseScreen = lazy(() => import('../screen/footer-page/terms-of-use'));
const CompanyScreen = lazy(() => import('../screen/footer-page/company'));
const SCTScreen = lazy(() => import('../screen/footer-page/sct'));
const SuggestionGuideScreen = lazy(() => import('../screen/suggestion-page'));
const ProjectGuide = lazy(() => import('../screen/project-screen/project-guide'));
const Suggestion = lazy(() => import('../screen/suggestion-screen'));
// const SuggestionScreen = lazy(() => import('../screen/suggestion-guide-page'));
const SuggestionDetailScreen = lazy(() => import('../screen/suggestion-guide-detail'));
const SeminarsScreen = lazy(() => import('../screen/seminars'));

const ProjectsScreen = lazy(() => import('../screen/project-management'));
// const ProjectsScreen = lazy(() => import('../screen/project-screen'));
// const ProjectDetailScreen = lazy(() => import('../screen/project-screen/detail'));
const ProjectChatDealRateScreen = lazy(() => import('../screen/project-chat-deal'));


const Notification = lazy(() => import('../screen/notification-screen'));
// const UserProfile = lazy(() => import('../pages/user-profile'));
// const Settings = lazy(() => import('../pages/settings'));
const NotFound = lazy(() => import('../pages/not-found'));

const VerticalBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const PrivateRoute = ({ condition, redirect, ...props }) => {
  condition = condition();

  if (condition) return <Route {...props} />;
  return <Redirect to={redirect} />;
};
PrivateRoute.propTypes = {
  condition: PropTypes.func,
  redirect: PropTypes.string,
};

@withRouter
class Routes extends Component {
  static propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
  };

  _renderLazyComponent = (LazyComponent, params) => props => <LazyComponent {...props} {...params} />;

  render() {
    const { history } = this.props
    const currentLang = localStorage.getItem('currentLang');
    if (!currentLang) {
      localStorage.setItem('currentLang', 'ja');
    }

    const myPageUrls = [
      '/top',
      '/news',
      '/setting',
      '/my-page',
      '/policy',
      '/terms-of-use',
      '/company',
      '/sct',
      '/seminars',
      '/suggestion',
      '/project',
      '/project_guide',
      '/suggestion_guide',
      '/new_suggestion',
    ]

    const isHome = !myPageUrls.some((url) => history.location.pathname.includes(url))
    return (
      <VerticalBox>

        {isHome && (
          <HeaderDaoSite />
        )}
        <Suspense
          fallback={
            <Page>
              <Loading size="large" />
            </Page>
          }
        >
          <ScrollToTop>
            <Switch>
              <Route exact path="/:lang/" component={this._renderLazyComponent(HomeScreen)} />
              <Route exact path="/:lang/top" component={this._renderLazyComponent(TopScreen)} />
              <Route exact path="/:lang/seminars" component={this._renderLazyComponent(SeminarsScreen)} />
              <Route exact path="/:lang/project" component={this._renderLazyComponent(ProjectsScreen)} />
              <Route exact path="/:lang/project/:tab" component={this._renderLazyComponent(ProjectsScreen)} />
              <Route exact path="/:lang/project/:tab/:id" component={this._renderLazyComponent(ProjectsScreen)} />
              <Route exact path="/:lang/project/:projectId/:projectOwnerId/:userId/detail" component={this._renderLazyComponent(ProjectChatDealRateScreen)} />

              <Route exact path="/:lang/news" component={this._renderLazyComponent(NewsScreen)} />
              <Route exact path="/:lang/news/:id" component={this._renderLazyComponent(NewsDetailScreen)} />
              <Route exact path="/:lang/my-page" component={this._renderLazyComponent(MyPageScreen)} />
              <Route exact path="/:lang/my-page/:tab" component={this._renderLazyComponent(MyPageScreen)} />
              <Route exact path="/:lang/my-page/:tab/:id" component={this._renderLazyComponent(MyPageScreen)} />

              <Route exact path="/:lang/setting" component={this._renderLazyComponent(SettingScreen)} />

              <Route exact path="/:lang/policy" component={this._renderLazyComponent(PolicyScreen)} />

              <Route exact path="/:lang/terms-of-use" component={this._renderLazyComponent(TermsOfUseScreen)} />

              <Route exact path="/:lang/company" component={this._renderLazyComponent(CompanyScreen)} />
              <Route exact path="/:lang/sct" component={this._renderLazyComponent(SCTScreen)} />
              <Route exact path="/:lang/suggestion_guide" component={this._renderLazyComponent(SuggestionGuideScreen)} />
              <Route exact path="/:lang/project_guide" component={this._renderLazyComponent(ProjectGuide)} />
              {/* <Route exact path="/:lang/suggestion" component={this._renderLazyComponent(SuggestionScreen)} /> */}

              <Route exact path="/:lang/suggestion" component={this._renderLazyComponent(Suggestion)} />
              <Route exact path="/:lang/suggestion/:tab" component={this._renderLazyComponent(Suggestion)} />
              <Route exact path="/:lang/suggestion/:tab/:id" component={this._renderLazyComponent(Suggestion)} />
              <Route exact path="/:lang/suggestions/:id" component={this._renderLazyComponent(SuggestionDetailScreen)} />


              {/* <Route exact path="/:lang/product-details/:id" component={this._renderLazyComponent(ProductDetails)} />
              <Route exact path="/:lang/category/:id" component={this._renderLazyComponent(CategoryDetails)} /> */}

              {/* <PrivateRoute
                exact
                condition={() => Request.getAccessToken()}
                redirect="/ja"
                path="/:lang/settings"
                component={this._renderLazyComponent(Settings)}
              />
              <PrivateRoute
                exact
                condition={() => Request.getAccessToken()}
                redirect="/ja"
                path="/:lang/my-page"
                component={this._renderLazyComponent(UserProfile)}
              />
              <PrivateRoute
                exact
                condition={() => Request.getAccessToken()}
                redirect="/ja"
                path="/:lang/my-page/:tab"
                component={this._renderLazyComponent(UserProfile)}
              /> */}

              {/* <PrivateRoute
                exact
                condition={() => Request.getAccessToken()}
                redirect="/ja"
                path="/:lang/notification"
                component={this._renderLazyComponent(Notification)}
              /> */}
              <PrivateRoute
                condition={() => Request.getAccessToken()}
                redirect="/ja"
                path="/:lang/notification/:tab/:id"
                component={this._renderLazyComponent(Notification)}
              />

              <Route path="/:lang/not-found" component={this._renderLazyComponent(NotFound)} />
              <Redirect to="/ja/" />
            </Switch>
          </ScrollToTop>
        </Suspense>
      </VerticalBox>
    );
  }
}

export default Routes;
